export const pageSize = 100;
export const header = [
  {
    name: 'Дата',
    key: 'date',
    width: '144px',
    userClass: 'table-item_left',
  },
  {
    name: 'Игра',
    key: 'name',
    width: '184px',
    userClass: 'table-item_left',
  },
  {
    name: 'Тип игры',
    key: 'type_id',
    width: '160px',
    userClass: 'table-item_left',
  },
  {
    name: 'Продукт',
    key: 'product_id',
    width: '160px',
    userClass: 'table-item_left',
  },
];
export const mobileHeader = [
  {
    name: 'Название',
    key: 'name',
    width: '50%',
    userClass: 'table-item_left',
  },
  {
    name: 'Дата',
    key: 'date',
    width: '50%',
    userClass: 'table-item_left',
  },
];

export const mobileHeaderDetailed = [
  {
    name: 'Тип игры',
    key: 'type_id',
    width: '160px',
    userClass: 'table-item_left',
  },
  {
    name: 'Продукт',
    key: 'product_id',
    width: '160px',
    userClass: 'table-item_left',
  },
];
export const defaultSort = { field: 'date', direction: 'desc' };
