import React, { useState } from 'react';
import Navigation from './Navigation/Navigation';
import Lottery from './Lottery/Lottery';
import Game from './Game/Game';
import './AdminModule.css';


function AdminModule() {
  const [selectedTab, setSelectedTab] = useState('games');


  return (
    <>
      <Navigation
        onClick={(tab) => setSelectedTab(tab)}
        selectedTab={selectedTab}
      />

      {selectedTab === 'players' && <Lottery />}
      {selectedTab === 'games' && <Game />}
    </>
  );
}


export default AdminModule;
