/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import './TableFilters.css';
import arrowLeft from '../../../../assets/icons/arrowLeft.svg';
import arrowRight from '../../../../assets/icons/arrowRight.svg';


function FiltersModal({
  onCloseFilterModal, onSaveFilterModal, inputValues,
}) {
  const [selectedValues, setSelectedValues] = useState({
    dateFrom: null, dateTo: null,
  });
  const [selectedYear, setSelectedYear] = useState(dayjs().year());


  useEffect(async () => {
    setSelectedValues(inputValues);
  }, [inputValues]);

  const onSave = () => {
    onSaveFilterModal(selectedValues);
  };

  const onSelectDate = (selectedMonth) => {
    if (!selectedValues.dateFrom) {
      setSelectedValues({ ...selectedValues, dateFrom: `${selectedYear}-${selectedMonth}-01` });
    } else if (!selectedValues.dateTo) {
      setSelectedValues({ ...selectedValues, dateTo: dayjs(`${selectedYear}-${selectedMonth}-01`).endOf('month').format('YYYY-M-DD') });
    } else {
      setSelectedValues({ ...selectedValues, dateFrom: `${selectedYear}-${selectedMonth}-01`, dateTo: null });
    }
  };

  const getMonthName = (date) => {
    let result = '';
    if (date) {
      const text = `${dayjs(date.split('T')[0]).format('MMMM').substring(0, 3)}.`;
      result = text[0].toUpperCase() + text.slice(1);
      result = `${result}/${dayjs(date.split('T')[0]).format('YYYY')}`;
    }
    return result;
  };

  return (
    <>
      <div className="filter-shadow active" />
      <div className="filter-modal-common filter-show-block">
        <div className="filter-modal-common__wrapper">
          <div className="filter-modal__calendar-period calendar-period">
            <div className="calendar-period__icon" />
            <div className="calendar-period__term">{`${getMonthName(selectedValues.dateFrom)}-${getMonthName(selectedValues.dateTo)}`}</div>
          </div>
          <div className="calendar-period__month">
            <img src={arrowLeft} alt="arrowLeft" className="calendar-period__month-left calendar-period__month-arrow" onClick={() => setSelectedYear(selectedYear - 1)} />
            <div className="calendar-period__month-name">{selectedYear}</div>
            <img src={arrowRight} alt="arrowRight" className="calendar-period__month-right calendar-period__month-arrow" onClick={() => setSelectedYear(selectedYear + 1)} />
          </div>
          {
              [[{ id: 1 }, { id: 2 }, { id: 3 }],
                [{ id: 4 }, { id: 5 }, { id: 6 }],
                [{ id: 7 }, { id: 8 }, { id: 9 }],
                [{ id: 10 }, { id: 11 }, { id: 12 }]].map((row) => (
                  <div className="filter-modal-date-row">
                    {row.map((cell) => {
                      const fullMonthName = dayjs(`${selectedYear}-${cell.id}-1`, 'YYYY-MM-DD').format('MMMM').substring(0, 3);
                      const monthName = fullMonthName.charAt(0).toUpperCase() + fullMonthName.slice(1);
                      const cellDate = dayjs(`${selectedYear}-${cell.id}-01`).startOf('D');
                      const dateFrom = dayjs(selectedValues.dateFrom?.split('T')[0], 'YYYY-MM-DD').startOf('D');
                      const dateTo = selectedValues.dateTo ? dayjs(selectedValues.dateTo.split('T')[0], 'YYYY-MM-DD').startOf('month') : null;
                      const isSelected = cellDate.isSame(dateFrom, 'month') || cellDate.isSame(dateTo, 'month');
                      const isBetweenDates = selectedValues.dateFrom && dateTo
                      && cellDate > dateFrom && cellDate < dateTo;
                      let style = {};
                      const selectedStyle = {};
                      if (isSelected) {
                        style = { borderRadius: '30px', background: '#02ACE5' };
                        selectedStyle.background = 'rgba(255, 255, 255, 0.10)';
                        selectedStyle.borderRadius = '30px';
                        if (cellDate.isSame(dateFrom, 'month') && dateTo) {
                          selectedStyle.borderRadius = '30px 0px 0px 30px';
                        } else if (cellDate.isSame(dateTo, 'month')) {
                          selectedStyle.borderRadius = '0px 30px 30px 0px';
                        }
                        if (dateTo?.isSame(dateFrom, 'month')) {
                          selectedStyle.borderRadius = '30px';
                        }
                      }
                      if (isBetweenDates) {
                        style = {
                          background: 'rgba(255, 255, 255, 0.10)',
                          borderRadius: '0px',
                        };
                        if ([1, 4, 7, 10].includes(cell.id)) {
                          style.borderRadius = '30px 0px 0px 30px';
                        }
                        if ([3, 6, 9, 12].includes(cell.id)) {
                          style.borderRadius = '0px 30px 30px 0px';
                        }
                      }
                      return (
                        <div
                          className="filter-modal-date-cell"
                          onClick={() => onSelectDate(cell.id)}
                          style={selectedStyle}
                        >
                          <div style={style}>
                            {`${monthName}.`}
                          </div>
                        </div>
                      );
                    })}
                  </div>
              ))
            }
        </div>

        <div className="filter-modal-button-wrapper">
          <div className="filter-btn filter-btn_clear filter-modal-button" onClick={onCloseFilterModal}>Закрыть</div>
          <div className="filter-btn filter-btn_save filter-modal-button" onClick={onSave}>Применить</div>
        </div>
      </div>
    </>
  );
}

FiltersModal.propTypes = {
  onCloseFilterModal: PropTypes.func.isRequired,
  onSaveFilterModal: PropTypes.func.isRequired,
  inputValues: PropTypes.shape().isRequired,
};

export default FiltersModal;
