import { createBrowserHistory as createHistory } from 'history';
import { get, orderBy } from 'lodash';
import dayjs from 'dayjs';
import api from './directualAPI/api';
import getExperienceValue from './getExperienceValue';


function handleError(error) {
  if (get(error, 'response.status', null) === 403) {
    return createHistory({ forceRefresh: true }).push('/login');
  }
  throw new Error(error);
}

export function auth(values) {
  return api
    .structure('auth')
    .sendData('auth', values)
    .then((resp) => ({
      code: get(resp, 'result[0].rsp_code', null),
      token: get(resp, 'result[0].token', null),
      city: get(resp, 'result[0].user_id.city_id', ''),
    }))
    .catch(handleError);
}

function parseCatalogueValue(object) {
  return {
    id: get(object, 'id', null),
    value: get(object, 'value', null),
    games: Number(get(object, 'games', 0)),
    seasonName: get(object, 'seasonName', null),
  };
}

function parseTeam(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
    years: get(object, 'years', null),
    months: get(object, 'months', null),
    days: get(object, 'days', null),
    power: get(object, 'power', 0),
    experience: get(object, 'experience', null),
    firstGameDate: get(object, 'firstGameDate', null) ? dayjs(get(object, 'firstGameDate')).format('D MMMM YYYY') : null,
    lastGameDate: get(object, 'lastGameDate', null) ? dayjs(get(object, 'lastGameDate')) : null,
  };
}

function parsePlayer(object) {
  return {
    id: get(object, 'uniqueId', null),
    name: get(object, 'name', null),
    teamName: get(object, 'teamName', null),
    cityName: get(object, 'cityName', null),
    gamesPlayed: get(object, 'gamesPlayed', null),
    result: get(object, 'result', null),
    experience: get(object, 'experience', null),
    teamId: get(object, 'team_id', null),
  };
}

function parseGame(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
    date: get(object, 'date', null) ? dayjs(get(object, 'date')).format('D MMMM YYYY') : null,
    product_id: get(object, 'product_id.value', null),
    city_id: get(object, 'city_id.value', null),
    type_id: get(object, 'type_id.value', ''),
    ratings: get(object, 'rating_ids', []).map((o) => parseCatalogueValue(o)),
    ratingsValue: get(object, 'rating_ids', []).map((o) => parseCatalogueValue(o)).map((o) => o.value).join(', '),
    errorMessage: get(object, 'errorRoundsInfo', null),
    isError: get(object, 'isError', null),
  };
}

function parseRating(object) {
  return {
    id: get(object, 'id', null),
    value: get(object, 'value', null),
    tildaCSV: get(object, 'tildaCSV', null),
  };
}

function parseParticipation(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
    city: get(object, 'city', null),
    date: get(object, 'date', null) ? dayjs(get(object, 'date')).format('D MMMM YYYY') : null,
    isError: get(object, 'isError', null),
    errorMessage: get(object, 'errorMessage', null),
  };
}

function parseGameResultByGameId(object) {
  return {
    id: get(object, 'id', null),
    teamId: get(object, 'team_id.id', null),
    teamName: get(object, 'team_id.name', null),
    teamCleanName: get(object, 'team_id.cleanName', null),
    place: get(object, 'place', null),
    result: get(object, 'result', null),
  };
}

function parseRound(object) {
  return {
    id: get(object, 'id', null),
    number: get(object, 'number', null),
    result: get(object, 'result', null).toString().replace('.', ','),
  };
}

function parseGameResult(object) {
  return {
    id: get(object, 'id', null),
    place: get(object, 'place', null),
    result: get(object, 'result', null).toString().replace('.', ','),
    teamName: get(object, 'team_id.name', null),
    teamId: get(object, 'team_id.id', null),
    experience: get(object, 'team_id.experience', null),
    power: get(object, 'team_id.power', 0),
    product: get(object, 'game_id.product_id.value', null),
    city: get(object, 'game_id.city_id.value', null),
    type: get(object, 'game_id.type_id.value', null),
    name: get(object, 'game_id.name', null),
    date: get(object, 'game_id.date', null) ? dayjs(get(object, 'game_id.date')).format('DD.MM.YYYY') : null,
    rounds: get(object, 'round_ids', []).map((r) => parseRound(r)),
    gameId: get(object, 'game_id.id', null),
  };
}

function parseTeamRating(object) {
  return {
    id: get(object, 'team_id.id', null) || get(object, 'team_id', null),
    teamName: get(object, 'teamName', null),
    result: get(object, 'result', null).toString().replace('.', ','),
    gamesPlayed: get(object, 'gamesPlayed', null),
    place: get(object, 'place', null),
    victories: get(object, 'victories', 0),
    average: get(object, 'average', 0).toFixed(2)?.toString().replace('.', ','),
    bestPlace: get(object, 'bestPlace', null),
    worstPlace: get(object, 'worstPlace', null),
    thirteenPlaces: get(object, 'thirteenPlaces', 0),
    prizePlaces: get(object, 'prizePlaces', 0),
    experience: get(object, 'team_id.experience', null),
    power: get(object, 'team_id.power', 0),
    averagePlace: get(object, 'averagePlace', 0).toFixed(1).replace('.', ','),
    ratingName: get(object, 'ratingName', null),
    ratingId: get(object, 'id', null),
    rating_id: get(object, 'rating_id.id', null),
    city: get(object, 'rating_id.city_id', null),
    product: get(object, 'rating_id.product_id', null),
    season: get(object, 'rating_id.season_id', null),
    changeDate: get(object, 'changeDate', null) ? dayjs(get(object, 'changeDate')).format('D.MM.YYYY') : null,
  };
}

function parsePlayerRating(object) {
  return {
    id: get(object, 'player_id.uniqueId', null),
    playerName: get(object, 'playerName', null) === 'Димоооооооооооооооооооон' ? 'Димоооооооооо оооооооооон' : get(object, 'playerName', null),
    teamName: get(object, 'player_id.teamName', null),
    teamId: get(object, 'player_id.team_id', null),
    result: get(object, 'result', null)?.toString().replace('.', ','),
    gamesPlayed: get(object, 'gamesPlayed', null),
    place: get(object, 'place', null),
    victories: get(object, 'victories', 0),
    average: get(object, 'average', 0).toFixed(2)?.toString().replace('.', ','),
    bestPlace: get(object, 'bestPlace', null),
    worstPlace: get(object, 'worstPlace', null),
    thirteenPlaces: get(object, 'thirteenPlaces', 0),
    prizePlaces: get(object, 'prizePlaces', 0),
    experience: getExperienceValue(parseFloat(get(object, 'player_id.result', 0))),
    averagePlace: get(object, 'averagePlace', 0).toFixed(1).replace('.', ','),
  };
}

function parsePlayerGameResult(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'game', null),
    date: get(object, 'date', null) ? dayjs(get(object, 'date')).format('D MMMM YYYY') : null,
    teamName: get(object, 'team', null),
    result: get(object, 'result', null).toString().replace('.', ','),
    place: get(object, 'place', null),
    product: get(object, 'product', null),
    city: get(object, 'city', null),
    type: get(object, 'game_id.type_id.value', null),
    gameId: get(object, 'game_id.id', null),
  };
}

function parseHallOfFame(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
    logo: get(object, 'logo_id.urlLink', null),
    title: get(object, 'title', null),
    seasons: get(object, 'seasons', null).split(','),
    index: get(object, 'index', null),
  };
}

export function getTeams(searchData) {
  return api
    .structure('team')
    .getData('getTeams', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseTeam(o)),
    }))
    .catch(handleError);
}

export function getPlayers(searchData) {
  return api
    .structure('player')
    .getData('getPlayers', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parsePlayer(o)),
    }))
    .catch(handleError);
}

export function getGames(searchData) {
  return api
    .structure('game')
    .getData('getGames', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseGame(o)),
    }))
    .catch(handleError);
}

export function getRatings(searchData) {
  return api
    .structure('rating')
    .getData('getRatings', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseCatalogueValue(o)),
    }))
    .catch(handleError);
}

export function getCities(searchData) {
  return api
    .structure('city')
    .getData('getCities', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseCatalogueValue(o)),
    }))
    .catch(handleError);
}

export function getProducts(searchData) {
  return api
    .structure('product')
    .getData('getProducts', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseCatalogueValue(o)),
    }))
    .catch(handleError);
}

export function getTypes(searchData) {
  return api
    .structure('type')
    .getData('getTypes', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: orderBy(get(resp, 'payload', []).map((o) => parseCatalogueValue(o)), 'value'),
    }))
    .catch(handleError);
}

export function getRatingById(searchData) {
  return api
    .structure('rating')
    .getData('getRatingById', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseRating(o)),
    }))
    .catch(handleError);
}

export function addGame(values) {
  return api
    .structure('game')
    .sendData('addGame', values)
    .then((resp) => parseGame(get(resp, 'result[0]', null)))
    .catch(handleError);
}

export function getGameImportStatus(searchData) {
  return api
    .structure('game')
    .getData('getGameImportStatus', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseGame(o)),
    }))
    .catch(handleError);
}

export function getParticipations(searchData) {
  return api
    .structure('participation')
    .getData('getParticipations', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseParticipation(o)),
    }))
    .catch(handleError);
}

export function getGameResultsByGameId(searchData) {
  return api
    .structure('gameResult')
    .getData('getGameResultsByGameId', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseGameResultByGameId(o)),
    }))
    .catch(handleError);
}

export function addParticipation(values) {
  return api
    .structure('participation')
    .sendData('addParticipation', values)
    .then((resp) => parseParticipation(get(resp, 'result[0]', null)))
    .catch(handleError);
}

export function getParticipationImportStatus(searchData) {
  return api
    .structure('participation')
    .getData('getParticipationImportStatus', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseParticipation(o)),
    }))
    .catch(handleError);
}

export function getPlayerRatingCatalogue(searchData) {
  return api
    .structure('playerRatingCatalogue')
    .getData('getPlayerRatingCatalogue', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseCatalogueValue(o)),
    }))
    .catch(handleError);
}

export function getGameById(searchData) {
  return api
    .structure('game')
    .getData('getGameById', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseGame(o)),
    }))
    .catch(handleError);
}

export function getGameResults(searchData) {
  return api
    .structure('gameResult')
    .getData('getGameResults', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseGameResult(o)),
    }))
    .catch(handleError);
}

export function getTeamRatings(searchData) {
  return api
    .structure('teamRating')
    .getData('getTeamRatings', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseTeamRating(o)),
    }))
    .catch(handleError);
}

export function getPlayerRatings(searchData) {
  return api
    .structure('playerRating')
    .getData('getPlayerRatings', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parsePlayerRating(o)),
    }))
    .catch(handleError);
}

export function getPlayerById(searchData) {
  return api
    .structure('player')
    .getData('getPlayerById', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parsePlayer(o)),
    }))
    .catch(handleError);
}

export function getPlayerGameResults(searchData) {
  return api
    .structure('playerGameResult')
    .getData('getPlayerGameResults', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parsePlayerGameResult(o)),
    }))
    .catch(handleError);
}

export function getTeamById(searchData) {
  return api
    .structure('team')
    .getData('getTeamById', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseTeam(o)),
    }))
    .catch(handleError);
}

export function getHallOfFame(searchData) {
  return api
    .structure('hallOfFame')
    .getData('getHallOfFame', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseHallOfFame(o)),
    }))
    .catch(handleError);
}

export function getSeasons(searchData) {
  return api
    .structure('season')
    .getData('getSeasons', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseCatalogueValue(o)),
    }))
    .catch(handleError);
}

function parseTeamTitle(object) {
  return {
    id: get(object, 'id', null),
    teamName: get(object, 'teamName', null),
    title: get(object, 'title', null),
    season: get(object, 'season', null),
  };
}

export function getTeamTitle(searchData) {
  return api
    .structure('teamTitle')
    .getData('getTeamTitle', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parseTeamTitle(o)),
    }))
    .catch(handleError);
}

function parsePlayerGameResultByTeam(object) {
  return {
    id: get(object, 'id', null),
    place: Number(get(object, 'place', null)),
    result: parseFloat(get(object, 'result', null)),
    playerName: get(object, 'player_id.name', null),
    playerResult: get(object, 'player_id.result', null),
    playerId: get(object, 'player_id.uniqueId', null),
  };
}

export function getPlayerGameResultByTeam(searchData) {
  return api
    .structure('playerGameResult')
    .getData('getPlayerGameResultByTeam', searchData)
    .then((resp) => ({
      tableSize: get(resp, 'pageInfo.tableSize', 0),
      data: get(resp, 'payload', []).map((o) => parsePlayerGameResultByTeam(o)),
    }))
    .catch(handleError);
}
