/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import './Player.css';
import TableWithFilters from '../../components/TableWithFilters/TableWithFilters';
import { getPlayerById, getPlayerGameResults } from '../../utils/http';
import { routeGenerator } from '../../utils/routes';


const headerResults = [
  {
    name: 'Игра',
    key: 'name',
    width: '174px',
    userClass: 'table-item_left',
  },
  {
    name: 'Дата',
    key: 'date',
    width: '144px',
    userClass: 'table-item_left',
  },
  {
    name: 'Город',
    key: 'city',
    width: '134px',
    userClass: 'table-item_left',
  },
  {
    name: 'Продукт',
    key: 'product',
    width: '100px',
    userClass: 'table-item_left',
  },
  {
    name: 'Тип игры',
    key: 'type',
    userClass: 'table-item_left table-item_prop-width',
  },
  {
    name: 'Команда',
    key: 'teamName',
    userClass: 'table-item_left table-item_prop-width',
  },
  {
    name: 'Место',
    key: 'place',
    width: '64px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '72px',
    userClass: 'table-item_center table-item_padding',
  },
];

const mobileHeader = [
  {
    name: 'Игра',
    key: 'name',
    width: '50%',
    userClass: 'table-item_left',
  },
  {
    name: 'Дата',
    key: 'date',
    width: '50%',
    userClass: 'table-item_left',
  },
];
const mobileDetailed = [
  {
    name: 'Город',
    key: 'city',
    width: '134px',
    userClass: 'table-item_left',
  },
  {
    name: 'Продукт',
    key: 'product',
    width: '100px',
    userClass: 'table-item_left',
  },
  {
    name: 'Тип игры',
    key: 'type',
    width: '120px',
    userClass: 'table-item_left',
  },
  {
    name: 'Команда',
    key: 'teamName',
    userClass: 'table-item_left table-item_balance-width',
  },
  {
    name: 'Место',
    key: 'place',
    width: '64px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '72px',
    userClass: 'table-item_center table-item_padding',
  },
];
function Player({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [player, setPlayer] = useState({});

  const [results, setResults] = useState([]);
  const [resultsSort, setResultsSort] = useState({});
  const [resultsPaginationTotal, setResultsPaginationTotal] = useState(1);
  const [resultsPaginationCurrent, setResultsPaginationCurrent] = useState(0);

  const fetchResults = async (newSort, newPagination, isLoadMore) => {
    const id = get(history, 'location.pathname').split('/')[3];
    setIsLoading(true);
    getPlayerGameResults({
      id,
      pageSize: 30,
      page: newPagination,
      sort: `${newSort.field},${newSort.direction}`,
    })
      .then((res) => {
        const data = res.data.map((d) => ({ ...d, id: d.gameId }));
        setResultsPaginationTotal(Math.ceil(res.tableSize / 30) - 1);
        setResultsPaginationCurrent(newPagination);
        setResults(isLoadMore ? [...results, ...data] : data);
        setIsLoading(false);
        setResultsSort(newSort);
      });
  };
  const loadMoreResults = () => {
    if (resultsPaginationTotal > resultsPaginationCurrent) {
      fetchResults(resultsSort, resultsPaginationCurrent + 1, true);
    }
  };
  const sortResults = (field, direction) => fetchResults({ field, direction }, 0);

  useEffect(async () => {
    const id = get(history, 'location.pathname').split('/')[3];
    await getPlayerById({ id })
      .then((res) => setPlayer(res.data[0]));
    await fetchResults({ field: 'date', direction: 'desc' }, 0);
  }, []);

  const onRedirectGame = (element) => history.push(routeGenerator.gameRoute(element.gameId));
  const onRedirectTeam = (teamId) => history.push(routeGenerator.teamRoute(teamId));

  return (
    <>
      <div className="breadcrumbs">
        <a onClick={() => history.goBack()}>назад</a>
      </div>
      <div className="center-position">
        <div className="team-personal">
          <h1>{player.name}</h1>
          <div className="team-param">
            <div className="team-param__exp team-param__item">
              <div className="team-param__item-body">{player.cityName}</div>
            </div>
            <div className="team-param__pow team-param__item">
              <span className="team-param__item-body" onClick={() => onRedirectTeam(player.teamId)} style={{ cursor: 'pointer' }}>{player.teamName}</span>
            </div>
            <div className="team-param__start team-param__item">
              <span className="team-param__item-body">{`${player.gamesPlayed} игр`}</span>
            </div>
            <div className="team-param__start team-param__item">
              <span className="team-param__item-body">{`${player.result} баллов`}</span>
            </div>
          </div>
        </div>
      </div>
      <TableWithFilters
        isShowFilters={false}
        isShowSearch={false}
        header={headerResults}
        data={results}
        onSort={sortResults}
        onFiltersChange={() => {}}
        onSelect={onRedirectGame}
        onLoadMore={loadMoreResults}
        searchPlaceholder="Название"
        isShowLoadMore={resultsPaginationTotal > resultsPaginationCurrent}
        isLoading={isLoading}
        tableClassName="table-normal"
        tableMobiClassName="mobi-table__two-col_leftcentering table-mobi__first-link"
        mobileHeader={mobileHeader}
        mobileDetailed={mobileDetailed}
      />
    </>
  );
}

Player.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Player);

