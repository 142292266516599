const citiesCatalogue = [
  {
    id: 'msk',
    value: 'Москва',
  },
  {
    id: 'spb',
    value: 'Санкт-Петербург',
  },
  {
    id: 'nn',
    value: 'Нижний Новгород',
  },
  {
    id: 'online',
    value: 'Online',
  },
];

export default citiesCatalogue;
