import React from 'react';
import './Achievements.css';
import achiveBelt from '../../assets/icons/achives/color-belt.svg';
import achivePower from '../../assets/icons/achives/team-power.svg';
import achiveHonor1 from '../../assets/images/honor/1.jpg';
import achiveHonor2 from '../../assets/images/honor/2.jpg';
import achiveHonor3 from '../../assets/images/honor/3.jpg';
import achiveHonor4 from '../../assets/images/honor/4.jpg';
import achiveHonor5 from '../../assets/images/honor/5.jpg';
import achiveHonor6 from '../../assets/images/honor/6.jpg';
import achiveHonorMobi1 from '../../assets/images/honor/mobi/1.jpg';
import achiveHonorMobi2 from '../../assets/images/honor/mobi/2.jpg';
import achiveHonorMobi3 from '../../assets/images/honor/mobi/3.jpg';
import achiveHonorMobi4 from '../../assets/images/honor/mobi/4.jpg';
import achiveHonorMobi5 from '../../assets/images/honor/mobi/5.jpg';
import achiveHonorMobi6 from '../../assets/images/honor/mobi/6.jpg';
import achiveHonor7 from '../../assets/images/honor-count/1.jpg';
import achiveHonor8 from '../../assets/images/honor-count/2.jpg';
import achiveHonor9 from '../../assets/images/honor-count/3.jpg';


function Achievements() {
  return (
    <>
      <div className="achive-wrapper">
        <div className="achive-prev">
          <h1>Система достижений Squiz</h1>
          <p>У каждой команды Squiz есть 2 параметра, которые отображают ее крутость</p>
        </div>
        <div className="achive-legend">
          <div className="achive-legend__column achive-legend__belt">
            <img className="achive-legend__belt-img" src={achiveBelt} alt="Пояс соответствующего цвета" />
            <h3>Пояс соответствующего цвета</h3>
            <p>Зависит от суммы набранных баллов в барных играх. Чем больше баллов, тем круче пояс.</p>
          </div>
          <div className="achive-legend__column achive-legend__power">
            <img className="achive-legend__power-img" src={achivePower} alt="Пояс соответствующего цвета" />
            <h3>Сила команды</h3>
            <p>Зависит от баллов, которые набирала команда, по сравнению с баллами, которые набирали победители игр, в которых играла команда.</p>
          </div>
        </div>
        <div className="achive-power">
          <h2>
            Как рассчитывается
            <span>Сила команды</span>
          </h2>
          <div className="achive-power__formulas">
            <div className="achive-power__formulas-column achive-power__formulas-first">
              <div className="formulas-first__result">Сила в конкретной игре</div>
              <div className="formulas-first__equal">=</div>
              <div className="formulas-first__form">
                <span className="formulas-first__divisible">Баллы команды</span>
                <span className="formulas-first__divider">Баллы победителя</span>
              </div>
            </div>
            <div className="achive-power__formulas-column achive-power__formulas-second">
              <div className="formulas-second__result">Сила команды</div>
              <div className="formulas-second__equal">=</div>
              <div className="formulas-second__form">
                <span className="formulas-second__divisible">Среднее арифметическое</span>
                <span className="formulas-second__divider">Силы во всех играх команды</span>
              </div>
            </div>
          </div>
        </div>
        <div className="archive-belts">
          <h2>Пояса</h2>
          <div className="archive-belts__wrapper">
            <div className="archive-belts__row">100</div>
            <div className="archive-belts__row">300</div>
            <div className="archive-belts__row">600</div>
            <div className="archive-belts__row archive-belts__honor">1000</div>
            <div className="archive-belts__row">1500</div>
            <div className="archive-belts__row archive-belts__honor">2100</div>
            <div className="archive-belts__row">2800</div>
            <div className="archive-belts__row">3600</div>
            <div className="archive-belts__row archive-belts__honor">4500</div>
            <div className="archive-belts__row">5500</div>
            <div className="archive-belts__row">6600</div>
            <div className="archive-belts__row archive-belts__honor">7800</div>
            <div className="archive-belts__row">9100</div>
            <div className="archive-belts__row">10500</div>
            <div className="archive-belts__row archive-belts__honor">12000</div>
            <div className="archive-belts__row">13600</div>
            <div className="archive-belts__row">15300</div>
            <div className="archive-belts__row archive-belts__honor">17100</div>
          </div>
        </div>
        <div className="archive-honors archive-honors_1">
          <h2 className="archive-honors_1">Награды за баллы</h2>
          <div className="archive-honors__wrapper">
            <div className="archive-honors__item archive-honors__item_1000">
              <p>При достижении 1000 баллов команда получает персональную деревянную табличку на стол, вместо стандартной</p>
              <img
                src={window.innerWidth <= 1000 ? achiveHonorMobi1 : achiveHonor1}
                alt=""
              />
            </div>
            <div className="archive-honors__item archive-honors__item_2100">
              <p>При достижении желтого пояса команда получает возможность выходить на награждение под свою музыку</p>
              <img
                src={window.innerWidth <= 1000 ? achiveHonorMobi2 : achiveHonor2}
                alt=""
              />
            </div>
            <div className="archive-honors__item archive-honors__item_4500">
              <p>При достижении зеленого пояса команда получает персональную гифку на представление команд в начале игры</p>
              <img
                src={window.innerWidth <= 1000 ? achiveHonorMobi3 : achiveHonor3}
                alt=""
              />
            </div>
            <div className="archive-honors__item archive-honors__item_7800">
              <p>При достижении синего пояса команда получает ручку с гравировкой названия команды</p>
              <img
                src={window.innerWidth <= 1000 ? achiveHonorMobi4 : achiveHonor4}
                alt=""
              />
            </div>
            <div className="archive-honors__item archive-honors__item_12000">
              <p>При достижении красного пояса команда получает партию носков Squiz с персональным дизайном</p>
              <img
                src={window.innerWidth <= 1000 ? achiveHonorMobi5 : achiveHonor5}
                alt=""
              />
            </div>
            <div className="archive-honors__item archive-honors__item_17100">
              <p>При достижении черного пояса команда получает толстовки с персональным дизайном</p>
              <img
                src={window.innerWidth <= 1000 ? achiveHonorMobi6 : achiveHonor6}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="archive-honors archive-honors_2">
          <h2 className="archive-honors_2">Награды за количество игр</h2>
          <div className="archive-honors__wrapper_2">
            <div className="archive-honors_2__item">
              <h3>100 сыгранных игр</h3>
              <p>Команда получает торт с названием команды</p>
              <img src={achiveHonor7} alt="100 сыгранных игр" />
            </div>
            <div className="archive-honors_2__item">
              <h3>200 сыгранных игр</h3>
              <p>Команда получает футболки с персональным дизайном</p>
              <img src={achiveHonor8} alt="200 сыгранных игр" />
            </div>
            <div className="archive-honors_2__item">
              <h3>300 сыгранных игр</h3>
              <p>Команда получает бейсболки с персональным дизайном</p>
              <img src={achiveHonor9} alt="300 сыгранных игр" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default Achievements;
