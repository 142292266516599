/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import citiesCatalogue from '../../../../utils/citiesCatalogue';
import productCatalogue from '../../../../utils/productCatalogue';
import loop from '../../../../assets/icons/loop.svg';
import './TableFilters.css';
import FiltersModal from './FiltersModal';
import FiltersDateModal from './FiltersDateModal';
import getDateSelectText from './getDateSelectText';
import { getRatings, getTypes } from '../../../../utils/http';


const typeCatalogue = [{ id: 'month', value: 'За месяц' }, { id: 'all', value: 'За все время' }];
function TableFilters({
  onFiltersChange, searchPlaceholder, isShowDetailed, onChangeDetailed, filters, tableFilterClassName, defaultFiltersValue,
}) {
  const [isShowFiltersModal, setIsShowFiltersModal] = useState(null);
  const [modalCatalogue, setModalCatalogue] = useState([]);
  const [modalDefaultValue, setModalDefaultValue] = useState(null);
  const [ratingCatalogue, setRatingCatalogue] = useState([]);
  const [gameTypeCatalogue, setGameTypeCatalogue] = useState([]);
  const [delayFetch, setDelayFetch] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState({
    city: citiesCatalogue[0].id,
    product: productCatalogue[0].id,
    season: 'all',
    dateFrom: null,
    dateTo: null,
    searchText: '',
    type: 'month',
    gameType: 'all',
    gameTypeValue: 'all',
    isChanged: false,
  });
  const [prevFilters, setPrevFilters] = useState({
    city: citiesCatalogue[0].id,
    product: productCatalogue[0].id,
    season: 'all',
    dateFrom: null,
    dateTo: null,
    searchText: '',
    type: 'month',
    gameType: 'all',
    gameTypeValue: 'all',
    isChanged: false,
  });

  useEffect(() => {
    if (filters.includes('rating')) {
      getRatings({ pageSize: 120, city: defaultFiltersValue.city, product: defaultFiltersValue.product })
        .then((res) => {
          const ratings = res.data.map((e) => ({ id: e.id, value: e.seasonName }));
          setRatingCatalogue(ratings);
          if (defaultFiltersValue) {
            let selectedSeason = `${defaultFiltersValue.city}-${defaultFiltersValue.product}-all`;
            if (!ratings.find((e) => e.id === selectedSeason)) {
              selectedSeason = ratings[0]?.id;
            }
            selectedSeason = defaultFiltersValue.season || selectedSeason;
            setSelectedFilters({ ...selectedFilters, ...defaultFiltersValue, season: selectedSeason });
            setPrevFilters({ ...selectedFilters, ...defaultFiltersValue, season: selectedSeason });
          }
        });
    } else if (filters.includes('gameType') && gameTypeCatalogue.length === 0) {
      getTypes({ pageSize: 60 })
        .then((res) => {
          const gameTypes = [{ id: 'all', value: 'Все типы игр' }, { id: 'classic', value: 'Классические' }, ...res.data.map((e) => ({ id: e.id, value: e.value }))];
          setGameTypeCatalogue(gameTypes);
          setSelectedFilters({ ...selectedFilters, ...defaultFiltersValue });
          setPrevFilters({ ...selectedFilters, ...defaultFiltersValue });
        });
    } else {
      setSelectedFilters({ ...selectedFilters, ...defaultFiltersValue });
      setPrevFilters({ ...selectedFilters, ...defaultFiltersValue });
    }
  }, [JSON.stringify(defaultFiltersValue)]);

  useEffect(async () => {
    if (selectedFilters.isChanged) {
      const dateFromMonth = selectedFilters.dateFrom ? (Number(selectedFilters.dateFrom.split('-')[1])).toFixed(0) : null;
      const dateFrom = selectedFilters.dateFrom ? dateFromMonth.length === 1 ? `0${dateFromMonth}` : dateFromMonth : null;
      const dateFromDay = selectedFilters.dateFrom ? (Number(selectedFilters.dateFrom.split('-')[2].split('T')[0])).toFixed(0) : null;
      const dateToMonth = selectedFilters.dateTo ? (Number(selectedFilters.dateTo.split('-')[1])).toFixed(0) : null;
      const dateTo = selectedFilters.dateTo ? dateToMonth.length === 1 ? `0${dateToMonth}` : dateToMonth : null;
      const dateToDay = selectedFilters.dateTo ? (Number(selectedFilters.dateTo.split('-')[2].split('T')[0])).toFixed(0) : null;
      const filtersForFetch = {
        ...selectedFilters,
        city: selectedFilters.city,
        product: selectedFilters.product === 'all' ? null : selectedFilters.product,
        season: selectedFilters.season,
        type: selectedFilters.type,
        gameType: selectedFilters.gameType === 'all' ? null : selectedFilters.gameType,
        gameTypeValue: selectedFilters.gameType === 'classic' ? null : selectedFilters.gameType,
        dateFrom: selectedFilters.dateFrom ? `${selectedFilters.dateFrom.split('-')[0]}-${dateFrom}-${dateFromDay}T00:00:00.000Z` : null,
        dateTo: selectedFilters.dateTo ? `${selectedFilters.dateTo.split('-')[0]}-${dateTo}-${dateToDay}T00:00:00.000Z` : null,
      };
      if (filters.includes('rating') && (selectedFilters.city !== prevFilters.city || selectedFilters.product !== prevFilters.product)) {
        const newRatings = await getRatings({ pageSize: 60, city: selectedFilters.city, product: selectedFilters.product });
        const ratings = newRatings.data.map((e) => ({ id: e.id, value: e.seasonName }));
        setRatingCatalogue(ratings);
        let selectedSeason = `${selectedFilters.city}-${selectedFilters.product}-all`;
        if (!ratings.find((e) => e.id === selectedSeason)) {
          selectedSeason = ratings[0]?.id;
        }
        setSelectedFilters({ ...selectedFilters, season: selectedSeason, isChanged: false });
        filtersForFetch.season = selectedSeason;
      }
      setPrevFilters(selectedFilters);
      clearTimeout(delayFetch);
      setDelayFetch(setTimeout(() => onFiltersChange(filtersForFetch), 2000));
    }
    return null;
  }, [JSON.stringify(selectedFilters)]);

  const onCloseFilterModal = () => {
    setIsShowFiltersModal(false);
    setModalCatalogue([]);
  };
  const onSaveFilterModal = (value) => {
    switch (isShowFiltersModal) {
      case 'city':
        setSelectedFilters({ ...selectedFilters, city: value, isChanged: true });
        break;
      case 'product':
        setSelectedFilters({ ...selectedFilters, product: value, isChanged: true });
        break;
      case 'rating':
        setSelectedFilters({ ...selectedFilters, season: value, isChanged: true });
        break;
      case 'type':
        setSelectedFilters({ ...selectedFilters, type: value, isChanged: true });
        break;
      case 'date':
        setSelectedFilters({ ...selectedFilters, ...value, isChanged: true });
        break;
      case 'gameType':
        setSelectedFilters({ ...selectedFilters, gameType: value, isChanged: true });
        break;
      default: break;
    }
    setModalDefaultValue(null);
    setModalCatalogue([]);
    setIsShowFiltersModal(null);
  };

  const onOpenFilterModal = (type) => {
    let catalogue = [];
    let value = null;
    switch (type) {
      case 'city':
        catalogue = citiesCatalogue;
        if (searchPlaceholder === 'Участник') {
          catalogue = citiesCatalogue.filter((e) => e.id === 'msk' || e.id === 'spb');
        }
        value = selectedFilters.city;
        break;
      case 'product':
        catalogue = productCatalogue;
        if (filters.includes('gameType')) {
          catalogue = [{ id: 'all', value: 'Все продукты' }, ...productCatalogue];
        }
        value = selectedFilters.product;
        break;
      case 'rating':
        catalogue = ratingCatalogue;
        value = selectedFilters.season;
        break;
      case 'type':
        catalogue = typeCatalogue;
        value = selectedFilters.type;
        break;
      case 'gameType':
        catalogue = gameTypeCatalogue;
        value = selectedFilters.gameType;
        break;
      default: break;
    }
    setModalDefaultValue(value);
    setModalCatalogue(catalogue);
    setIsShowFiltersModal(type);
  };

  const getFilterValue = (filter) => {
    switch (filter) {
      case 'city':
        return citiesCatalogue.find((e) => e.id === selectedFilters.city)?.value;
      case 'product':
        return [{ id: 'all', value: 'Все продукты' }, ...productCatalogue].find((e) => e.id === selectedFilters.product)?.value;
      case 'rating':
        return selectedFilters.season ? ratingCatalogue.find((e) => e.id === selectedFilters.season)?.value : 'Рейтинг';
      case 'date':
        return selectedFilters.dateFrom && selectedFilters.dateTo ? getDateSelectText(selectedFilters.dateFrom, selectedFilters.dateTo) : 'Дата';
      case 'type':
        return typeCatalogue.find((e) => e.id === selectedFilters.type)?.value;
      case 'gameType':
        return gameTypeCatalogue.find((e) => e.id === selectedFilters.gameType)?.value;
      default: return null;
    }
  };

  return (
    <>
      <div className={`${tableFilterClassName} tableFilters-filters_margin`}>
        <div className="tableFilters-filters-wrapper">
          <div className="tableFilters__filters-input">
            <img className="tableFilters-filters-img" src={loop} alt="logo" />
            <input className="tableFilters-filters-input" placeholder={searchPlaceholder} onChange={(e) => setSelectedFilters({ ...selectedFilters, searchText: e.target.value, isChanged: true })} />
          </div>
          {isShowDetailed && (
            <div className="tableFilters-more">
              <div className="tableFilters-more__title">Подробно</div>
              <div className="tableFilters-more__wrapper">
                <input
                  type="checkbox"
                  className="tableFilters-more__input"
                  id="switch"
                  onChange={(e) => onChangeDetailed(e.target.checked)}
                />
                <label className="tableFilters-more__label" htmlFor="switch" />
              </div>
            </div>
          )}
          <div className="tableFilters__swipe-wrapper">
            <div
              className="tableFilters__lists"
            >
              {filters.map((filter) => (
                <div
                  key={filter}
                  className={`tableFilters__item fontInter ${isShowFiltersModal === filter ? 'active' : ''}`}
                  onClick={() => onOpenFilterModal(filter)}
                >
                  <span>
                    {getFilterValue(filter)}
                  </span>
                </div>
              ))}
            </div>
            {['city', 'product', 'rating', 'type', 'gameType'].includes(isShowFiltersModal) && (
            <FiltersModal
              onCloseFilterModal={onCloseFilterModal}
              onSaveFilterModal={onSaveFilterModal}
              defaultValue={modalDefaultValue}
              catalogue={modalCatalogue}
            />
            )}
            {['date'].includes(isShowFiltersModal) && (
            <FiltersDateModal
              onCloseFilterModal={onCloseFilterModal}
              onSaveFilterModal={onSaveFilterModal}
              inputValues={{ dateFrom: selectedFilters.dateFrom, dateTo: selectedFilters.dateTo }}
            />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

TableFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  isShowDetailed: PropTypes.bool.isRequired,
  onChangeDetailed: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableFilterClassName: PropTypes.string.isRequired,
  defaultFiltersValue: PropTypes.shape(),
};

TableFilters.defaultProps = {
  defaultFiltersValue: null,
};

export default TableFilters;
