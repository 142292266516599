/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TableWithFilters from '../../components/TableWithFilters/TableWithFilters';
import { getGameById, getGameResults } from '../../utils/http';
import { routeGenerator } from '../../utils/routes';


const mobileHeader = [
  {
    name: 'Место',
    key: 'place',
    width: '66px',
    userClass: 'table-item_center table-item_padding table-item_ellipsis',
  },
  {
    name: 'Команда',
    key: 'teamName',
    userClass: 'table-item_left table-item_balance-width',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '70px',
    userClass: 'table-item_right table-item_mobi-score',
  },
];

function Game({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [game, setGame] = useState({});
  const [headerResults, setHeaderResults] = useState([]);
  const [mobileHeaderDetailed, setMobileHeaderDetailed] = useState([]);
  const [isManyRounds, setIsManyRounds] = useState(false);
  const [results, setResults] = useState([]);

  const fetchResults = async (newSort) => {
    const id = get(history, 'location.pathname').split('/')[3];
    setIsLoading(true);

    const initRequest = await getGameResults({
      id, pageSize: 10, page: 0, sort: `${newSort.field},${newSort.direction}`,
    });
    const { tableSize } = initRequest;

    getGameResults({
      id,
      pageSize: tableSize,
      sort: `${newSort.field},${newSort.direction}`,
    })
      .then((response) => {
        let res = response.data;
        const rounds = [];
        const mobileRounds = [];
        const roundsNumber = Math.max(...res.map((gameRes) => gameRes.rounds.length));
        setIsManyRounds(roundsNumber > 7);
        if (res.length > 0) {
          for (let i = 1; i <= roundsNumber; i += 1) {
            rounds.push({
              name:
  <div className="table-item__desc-hide">
    {i}
  </div>,
              key: `round${i}`,
              width: `${40}px`,
            });
            mobileRounds.push({
              name:
  <div className="table-item__desc-hide">
    <span style={{ display: 'block' }}>Раунд</span>
    {i}
  </div>,
              key: `round${i}`,
              width: `${40}px`,
            });
            res = res.map((result) => ({
              ...result,
              [`round${i}`]: result.rounds.find((round) => round.number === i)?.result,
            }));
          }
        }
        setHeaderResults([
          {
            name: 'Место',
            key: 'place',
            width: '68px',
            userClass: 'table-item_center',
          },
          {
            name: 'Опыт',
            key: 'experience',
            width: '68px',
            userClass: 'table-item_center',
          },
          {
            name: 'Сила',
            key: 'power',
            width: '58px',
            userClass: 'table-item_center',
          },
          {
            name: 'Команда',
            key: 'teamName',
            userClass: 'table-item_left table-item_prop-width',
          },
          ...rounds,
          {
            name: 'Итого',
            key: 'result',
            width: '72px',
            userClass: 'table-item_center',
          },
        ]);
        setMobileHeaderDetailed([
          {
            name: 'Опыт',
            key: 'experience',
            width: '68px',
            userClass: 'table-item_center',
          },
          {
            name: 'Сила',
            key: 'power',
            width: '58px',
            userClass: 'table-item_center',
          },
          ...mobileRounds,
          {
            name: 'Итого',
            key: 'result',
            width: '72px',
            userClass: 'table-item_center',
          },
        ]);
        setResults(res);
        setIsLoading(false);
      });
  };
  const sortResults = (field, direction) => fetchResults({ field, direction });

  useEffect(async () => {
    const gameId = get(history, 'location.pathname').split('/')[3];
    await getGameById({ id: gameId })
      .then((res) => setGame(res.data[0]));
    fetchResults({ field: 'result', direction: 'desc' }, 0);
  }, []);

  const onRedirectTeam = (element) => history.push(routeGenerator.teamRoute(element.teamId));

  return (
    <>
      <div className="breadcrumbs">
        <a onClick={() => history.goBack()}>назад</a>
      </div>
      <div className="center-position">
        <div className="team-personal">
          <h1>{game.name}</h1>
          <div className="team-param">
            {/* <div className="team-param__exp team-param__item">
              <div className="team-param__item-body">{game.name}</div>
            </div> */}
            <div className="team-param__pow team-param__item team-param__item_lite">
              <span className="team-param__item-body">{game.date}</span>
            </div>
            <div className="team-param__start team-param__item team-param__item_lite">
              <span className="team-param__item-body">{game.city_id}</span>
            </div>
            <div className="team-param__start team-param__item team-param__item_lite">
              <span className="team-param__item-body">{game.product_id}</span>
            </div>
            <div className="team-param__start team-param__item team-param__item_lite">
              <span className="team-param__item-body">{game.type_id}</span>
            </div>
          </div>
        </div>
        <div style={{ margin: '40px 0px' }} />
      </div>
      <TableWithFilters
        isShowFilters={false}
        isShowSearch={false}
        header={headerResults}
        mobileHeader={mobileHeader}
        mobileDetailed={mobileHeaderDetailed}
        data={results}
        onSort={sortResults}
        onFiltersChange={() => {}}
        onSelect={onRedirectTeam}
        onLoadMore={() => {}}
        searchPlaceholder="Название"
        isLoading={isLoading}
        tableClassName={isManyRounds ? null : 'table-normal_middle'}
        tableMobiClassName="table-mobi__exp-center table-mobi__second-link table-mobi"
      />
    </>
  );
}

Game.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Game);

