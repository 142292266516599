/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './Footer.css';
import logo from '../../assets/icons/logo.svg';
import vk from '../../assets/icons/vk.svg';
import fb from '../../assets/icons/fb.svg';
import instagram from '../../assets/icons/instagram.svg';
import tg from '../../assets/icons/tg.svg';


function Footer() {
  const onIconClick = (icon) => {
    switch (icon) {
      case 'tg':
        window.open('https://t.me/squizmsk', '_self');
        break;
      case 'instagram':
        window.open('https://instagram.com/squiz.ru?igshid=YmMyMTA2M2Y=', '_self');
        break;
      case 'vk':
        window.open('http://vk.com/squizru', '_self');
        break;
      case 'fb':
        window.open('https://m.facebook.com/squiz.ru/', '_self');
        break;
      default: break;
    }
  };

  return (
    <>
      <div className="footer-wrapper" id="footer">
        <div className="footer-top-row-wrapper">
          <img className="footer-logo" src={logo} alt="logo" />
          <div className="footer-icons-wrapper">
            <img src={vk} alt="logo" className="footer-icons-icon" onClick={() => onIconClick('vk')} />
            <img src={tg} alt="Telegram" className="footer-icons-icon" onClick={() => onIconClick('tg')} />
            <img src={fb} alt="logo" className="footer-icons-icon" onClick={() => onIconClick('fb')} />
            <img src={instagram} alt="logo" className="footer-icons-icon" onClick={() => onIconClick('instagram')} />
          </div>
        </div>
        <div className="footer-phone">+7 495 120 66 25</div>
        <div className="footer-email">squiz@squiz.ru</div>
        <div className="footer-bottom-row-wrapper">
          <div className="footer-address">Москва, Б. Новодмитровская, 23с3</div>
          <div className="footer-create">Сделано в si-team</div>
          <div className="footer-link">Политика в отношении обработки персональных данных</div>
        </div>
      </div>
    </>
  );
}

export default Footer;
