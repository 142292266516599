const productCatalogue = [
  {
    id: 'squiz',
    value: 'Squiz',
  },
  {
    id: 'ultima',
    value: 'Squiz Ultima',
  },
  {
    id: 'tv',
    value: 'Squiz. ТВ-шоу',
  },
  {
    id: 'tvoyaigra',
    value: 'Squiz. Твоя игра',
  },
  {
    id: 'funtazer',
    value: 'Фантазер',
  },
];

export default productCatalogue;
