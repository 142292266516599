import React from 'react';


function NotFound() {
  return (
    <div className="not-found-date">
      Упс. Кажется, ничего не нашлось. Измените настройки поиска
    </div>
  );
}

export default NotFound;
