/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import './ResultsTabs.css';
import 'swiper/swiper-bundle.css';
import teams from '../../assets/icons/teams.svg';
import players from '../../assets/icons/players.svg';
import games from '../../assets/icons/games.svg';
import achievements from '../../assets/icons/achievements.svg';
import hallOfFame from '../../assets/icons/hallOfFame.svg';
import { routes } from '../../utils/routes';


function ResultsTabs({ history }) {
  const [isSelectTab, setIsSelectTab] = useState(false);
  const [isMobile] = useState(window.innerWidth < 1000);

  const tabs = [
    {
      id: routes.teamRatings, value: 'Команды', logo: teams, key: 'team',
    },
    {
      id: routes.playerRatings, value: 'Участники', logo: players, key: 'player',
    },
    {
      id: routes.games, value: 'Игры', logo: games, key: 'game',
    },
    {
      id: routes.achievements, value: 'Достижения', logo: achievements, key: 'achievements',
    },
    {
      id: routes.hallOfFame, value: 'Зал славы', logo: hallOfFame, key: 'hallOfFame',
    }];
  const pathname = get(history, 'location.pathname', '');

  const handleButtonClick = (route) => {
    if (isSelectTab || !isMobile) {
      history.push(route);
    }
    if (isMobile) {
      setIsSelectTab(!isSelectTab);
    }
  };

  const isNotVisible = [routes.admin].includes(get(history, 'location.pathname', ''));
  if (isNotVisible) {
    return null;
  }

  return (
    <>
      <div className="resultsTabs">
        {tabs.map((tab) => {
          const isActive = pathname.includes(tab.key);
          return (
            <div
              className={`resultsTabs-tab-wrapper ${isActive ? 'active' : ''} ${isActive && isSelectTab ? 'toggle-arrow' : ''}`}
              key={tab.id}
              onClick={() => handleButtonClick(tab.id)}
              style={isSelectTab || isActive || !isMobile ? { display: 'flex' } : { display: 'none' }}
            >
              <img src={tab.logo} alt="logo" className="resultsTabs-tab-icon" />
              <div className="resultsTabs-tab-text">{tab.value}</div>
            </div>
          );
        })}
      </div>
    </>
  );
}


ResultsTabs.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(ResultsTabs);
