/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import md5 from 'md5';
import { v4 as uuidv4 } from 'uuid';
import TableWithFilters from '../../../components/TableWithFilters/TableWithFilters';
import {
  getParticipations, addParticipation, getParticipationImportStatus, getGames, getGameResultsByGameId,
} from '../../../utils/http';
import AddLotteryModal from './AddLoteryModal/AddLoteryModal';
import getTeamCleanName from '../../../utils/getTeamCleanName';
import citiesCatalogue from '../../../utils/citiesCatalogue';


const cookies = new Cookies();
const pageSize = 100;
const defaultSort = { field: 'date', direction: 'desc' };
export const header = [
  {
    name: 'Дата',
    key: 'date',
    width: '144px',
    userClass: 'table-item_left',
  },
  {
    name: 'Игра',
    key: 'name',
    width: '184px',
    userClass: 'table-item_left',
  },
  {
    name: 'Город',
    key: 'city',
    width: '160px',
    userClass: 'table-item_left',
  },
];
function Lottery() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [paginationCurrent, setPaginationCurrent] = useState(0);
  const [step, setStep] = useState(1);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [newObj, setNewObj] = useState({});
  const [importStatusFetch, setImportStatusFetch] = useState(null);
  const [customError, setCustomError] = useState(null);
  const [allGameResults, setAllGameResults] = useState([]);
  const [city] = useState(cookies.get('city'));
  const [customCity, setCustomCity] = useState();

  const fetchData = (newPagination, isLoadMore) => {
    setIsLoading(true);
    const cityObj = city ? citiesCatalogue.find((c) => c.id === city) : null;
    getParticipations({
      pageSize,
      page: newPagination,
      city: cityObj?.value,
      ...defaultSort,
    })
      .then((res) => {
        setPaginationTotal(Math.ceil(res.tableSize / pageSize) - 1);
        setPaginationCurrent(newPagination);
        setData(isLoadMore ? [...data, ...res.data] : res.data);
        setIsLoading(false);
      });
  };

  useEffect(async () => {
    const cityObj = city ? citiesCatalogue.find((c) => c.id === city) : null;
    setCustomCity(cityObj ? { value: cityObj.id, label: cityObj.value } : null);
    fetchData(0);
  }, []);


  const onLoadMore = () => {
    if (paginationTotal > paginationCurrent) {
      fetchData(paginationCurrent + 1, true);
    }
  };

  const fetchImportStatus = (id) => {
    let counter = 0;
    setNewObj((prevObj) => {
      counter = prevObj.counter - 1;
      return { ...prevObj, counter };
    });
    if (counter !== 0) {
      getParticipationImportStatus({ id })
        .then((res) => {
          const result = res.data[0];
          if (result.isError || (result.isError !== null && result.isError === false && result.errorMessage === null)) {
            setImportStatusFetch((prevImportStatusFetch) => {
              clearInterval(prevImportStatusFetch);
              return null;
            });
            if (result.isError) {
              setNewObj((prevObj) => ({
                ...prevObj, isError: true, errorText: result.errorMessage, isSuccess: false,
              }));
            } else {
              setNewObj((prevObj) => ({
                ...prevObj, isError: false, errorText: null, isSuccess: true,
              }));
            }
          }
        });
    } else {
      setImportStatusFetch((prevImportStatusFetch) => {
        clearInterval(prevImportStatusFetch);
        return null;
      });
      setNewObj((prevObj) => ({
        ...prevObj, isError: true, errorText: 'Превышено время ожидания обработки. Напишите Никите', isSuccess: false,
      }));
    }
  };

  const onCloseModal = () => {
    setStep(1);
    setIsShowAddModal(false);
    setNewObj({});
  };

  const onNextStep = (newStep) => {
    if (newStep === 2) {
      setIsLoading(true);
      let error = false;
      const name = document.getElementById('name').value;
      const date = document.getElementById('date').value;
      const result = document.getElementById('result').value;
      const isOut = document.getElementById('isOut').checked;
      getGames({ city: newObj?.city?.value, name, date })
        .then((gamesRes) => {
          if (gamesRes.data.length === 0) {
            error = 'Не нашли игр по таким параметрам';
          }
          if (gamesRes.data.length > 1) {
            error = 'Нашли несколько игр с такими параметрами';
          }

          if (error) {
            setCustomError(error);
            setIsLoading(false);
          } else {
            const newObjToSave = {
              name,
              city: newObj?.city?.label,
              product: gamesRes.data[0]?.product_id,
              gameId: gamesRes.data[0].id,
              type: gamesRes.data[0]?.type_id,
              date,
              isOut,
              counter: 10,
            };
            getGameResultsByGameId({ gameId: newObjToSave.gameId })
              .then(async (teamsRes) => {
                let gameResults = teamsRes.data;
                if (teamsRes.data.length === 0) {
                  error = 'Не нашли результатов игр';
                }
                if (error) {
                  setCustomError(error);
                  setIsLoading(false);
                } else if (teamsRes.tableSize > 30) {
                  const fullTeamsRes = await getGameResultsByGameId({ gameId: newObjToSave.gameId, pageSize: teamsRes.tableSize });
                  gameResults = fullTeamsRes.data;
                }
                const playerResults = [];
                result.split('\n').forEach((row, i) => {
                  const playerResult = {};
                  if (row.split(';').length !== 3) {
                    error += ` Обшика добавления данных. В строке ${i + 1} некорректное кол-во ячеек`;
                    return;
                  }
                  row.split(';').forEach((column, index) => {
                    if (!column || column === '') {
                      error += ` Обшика добавления данных. В строке ${i + 1} есть пустые значения`;
                      return;
                    }
                    switch (index) {
                      case 0:
                        playerResult.email = column;
                        break;
                      case 1:
                        playerResult.name = column;
                        break;
                      case 2:
                        playerResult.teamName = column;
                        break;
                      default: break;
                    }
                  });
                  playerResult.result = gameResults.find((g) => g.teamCleanName === getTeamCleanName(playerResult.teamName))?.result;
                  playerResult.place = gameResults.find((g) => g.teamCleanName === getTeamCleanName(playerResult.teamName))?.place;
                  playerResult.team = gameResults.find((g) => g.teamCleanName === getTeamCleanName(playerResult.teamName))?.teamName;
                  playerResult.teamId = gameResults.find((g) => g.teamCleanName === getTeamCleanName(playerResult.teamName))?.teamId;
                  playerResults.push(playerResult);
                });

                if (error) {
                  setCustomError(error);
                  setIsLoading(false);
                } else {
                  setCustomError(null);
                  setNewObj({
                    ...newObjToSave,
                    playerResults,
                    counter: 10,
                  });
                  setStep(newStep);
                  setAllGameResults(gameResults);
                  setIsLoading(false);
                }
              });
          }
        });
    }
    if (newStep === 3) {
      setIsLoading(true);
      let error = false;

      const playerRatingAllJson = { array: [] };
      const playerRatingMonthJson = { array: [] };
      const playerGameResultJson = { array: [] };
      const playerJson = { array: [] };
      newObj.playerResults.forEach((pr) => {
        if (pr.result === null || pr.result === undefined) {
          error = 'Заполните результаты';
          return;
        }
        playerJson.array.push({
          id: pr.email,
          email: pr.email,
          name: pr.name,
          teamName: pr.teamName,
          cityName: newObj.city,
          uniqueId: uuidv4(),
          team_id: pr.teamId,
        });

        playerGameResultJson.array.push({
          player_id: pr.email,
          city: newObj.city,
          place: pr.place,
          result: pr.result,
          date: newObj.date,
          product: newObj.product,
          type: newObj.type,
          team: pr.teamName,
          email: pr.email,
          player: pr.name,
          game: newObj.name,
          game_id: newObj.gameId,
          isOut: newObj.isOut,
          team_id: pr.teamId,
        });

        playerRatingAllJson.array.push({
          id: md5(pr.email),
          player_id: pr.email,
          playerName: pr.name,
          email: pr.email,
          teamName: pr.teamName,
          city: '',
          isMonthly: false,
          team_id: pr.teamId,
        },
        {
          id: md5(`${pr.email}${newObj.city}`),
          player_id: pr.email,
          playerName: pr.name,
          email: pr.email,
          teamName: pr.teamName,
          city: newObj.city,
          isMonthly: false,
          team_id: pr.teamId,
        });

        playerRatingMonthJson.array.push({
          id: md5(`${pr.email}${newObj.city}30`),
          player_id: pr.email,
          playerName: pr.name,
          email: pr.email,
          teamName: pr.teamName,
          city: newObj.city,
          isMonthly: true,
          team_id: pr.teamId,
        });
      });
      if (error) {
        setCustomError(error);
        setIsLoading(false);
      }
      const valuesToSave = {
        ...newObj,
        playerRatingAllJson,
        playerRatingMonthJson,
        playerGameResultJson,
        playerJson,
      };
      delete valuesToSave.counter;
      delete valuesToSave.playerResults;
      addParticipation(valuesToSave)
        .then((res) => {
          const newId = res.id;
          setImportStatusFetch(setInterval(() => fetchImportStatus(newId), 5000));
          setStep(newStep);
        });
    }
    if (newStep === 4) {
      onCloseModal();
    }
  };

  const onChangeTeam = (playerEmail, playerName, newTeam) => {
    const result = allGameResults.find((g) => g.teamCleanName === getTeamCleanName(newTeam || ''))?.result;
    const place = allGameResults.find((g) => g.teamCleanName === getTeamCleanName(newTeam || ''))?.place;
    const teamId = allGameResults.find((g) => g.teamCleanName === getTeamCleanName(newTeam || ''))?.teamId;
    const teamName = newTeam;
    setNewObj({
      ...newObj,
      playerResults: newObj.playerResults.map((p) => (p.name === playerName && p.email === playerEmail
        ? {
          ...p, team: newTeam, result, place, teamId, teamName,
        }
        : p)),
    });
  };

  return (
    <>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%',
      }}
      >
        <div
          style={{
            padding: '10px 20px',
            borderRadius: '5px',
            backgroundColor: '#02ACE5',
            marginBottom: '20px',
            cursor: 'pointer',
            color: '#fff',
          }}
          onClick={() => setIsShowAddModal(true)}
        >
          Добавить лотерею
        </div>
      </div>
      <TableWithFilters
        filters={[]}
        header={header}
        data={data}
        onSort={() => {}}
        onFiltersChange={() => {}}
        onSelect={() => {}}
        onLoadMore={onLoadMore}
        searchPlaceholder="Игра"
        isShowLoadMore={paginationTotal > paginationCurrent}
        isLoading={isLoading}
        mobileHeader={[]}
        mobileDetailed={[]}
        isShowSort={false}
        tableClassName="table-small"
        tableFilterClassName="center-position_804 center-position_804_mini"
        tableMobiClassName="mobi-table__two-col_leftcentering table-mobi__first-link"
      />
      {isShowAddModal && (
      <AddLotteryModal
        step={step}
        onNextStep={onNextStep}
        onCloseModal={onCloseModal}
        newObj={newObj}
        customError={customError}
        setNewObj={setNewObj}
        gameResults={allGameResults}
        onChangeTeam={onChangeTeam}
        isShowCitySelect={!city}
        customCity={customCity}
      />
      )}
    </>
  );
}


export default Lottery;
