import React from 'react';
import dayjs from 'dayjs';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { routes } from './utils/routes';
import PrivateRoute from './utils/privateRoute';
import PlayerRating from './pages/PlayerRatings/PlayerRating';
import GamesModule from './pages/Games/GamesModule';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import LoginModule from './pages/Login/LoginModule';
import AdminModule from './pages/Admin/AdminModule';
import Achievements from './pages/Achievements/Achievements';
import HallOfFameModule from './pages/HallOfFame/HallOfFameModule';
import Game from './pages/Games/Game';
import Player from './pages/Players/Player';
import Team from './pages/Teams/Team';
import TeamRating from './pages/TeamRatings/TeamRating';


require('dayjs/locale/ru');


export default function App() {
  dayjs.locale('ru');
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route key="games" path={routes.games} component={GamesModule} />
        <Route key="teamRatings" path={routes.teamRatings} component={TeamRating} />
        <Route key="playerRatings" path={routes.playerRatings} component={PlayerRating} />
        <Route key="achievements" path={routes.achievements} component={Achievements} />
        <Route key="hallOfFame" path={routes.hallOfFame} component={HallOfFameModule} />
        <Route key="login" path={routes.login} component={LoginModule} />
        <Route key="game" path={routes.game} component={Game} />
        <Route key="player" path={routes.player} component={Player} />
        <Route key="team" path={routes.team} component={Team} />
        <PrivateRoute path={routes.admin}><AdminModule /></PrivateRoute>
        <Route key="/" exact path={routes.any} render={() => (<Redirect to={routes.teamRatings} />)} />
      </Switch>
      <Footer />
    </Router>
  );
}


