export const pageSize = 100;
export const defaultSort = { field: 'place', direction: 'asc' };
export const detailedHeader = [
  {
    name: 'Место',
    key: 'place',
    width: '64px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Опыт',
    key: 'experience',
    width: '68px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Сила',
    key: 'power',
    width: '58px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Команда',
    key: 'teamName',
    userClass: 'table-item_left table-item_prop-width',
  },
  {
    name: 'Игры',
    key: 'gamesPlayed',
    width: '60px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '72px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Победы',
    key: 'victories',
    width: '80px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Ср. бал',
    key: 'average',
    width: '84px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: '1-3 места',
    key: 'prizePlaces',
    width: '93px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: '13 места',
    key: 'thirteenPlaces',
    width: '86px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Лучшее',
    key: 'bestPlace',
    width: '130px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Среднее',
    key: 'averagePlace',
    width: '92px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Худшее',
    key: 'worstPlace',
    width: '130px',
    userClass: 'table-item_center table-item_padding',
  },
];
export const shortHeader = [
  {
    name: 'Место',
    key: 'place',
    width: '66px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Опыт',
    key: 'experience',
    width: '68px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Сила',
    key: 'power',
    width: '56px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Команда',
    key: 'teamName',
    width: '287px',
    userClass: 'table-item_left table-item_prop-width',
  },
  {
    name: 'Игры',
    key: 'gamesPlayed',
    width: '60px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '72px',
    userClass: 'table-item_center table-item_padding',
  },
];
export const mobileHeader = [
  {
    name: 'Место',
    key: 'place',
    width: '66px',
    userClass: 'table-item_center table-item_padding table-item_ellipsis',
  },
  {
    name: 'Команда',
    key: 'teamName',
    userClass: 'table-item_left table-item_balance-width',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '72px',
    userClass: 'table-item_right table-item_mobi-score',
  },
];
export const mobileDetailed = [
  { name: 'Опыт', key: 'experience', width: '14%' },
  { name: 'Сила', key: 'power', width: '14%' },
  { name: 'Игры', key: 'gamesPlayed', width: '20%' },
  { name: 'Победы', key: 'victories', width: '20%' },
  { name: '1-3 места', key: 'prizePlaces', width: '20%' },
  { name: '13 места', key: 'thirteenPlaces', width: '20%' },
  { name: 'Средний балл', key: 'average', width: '20%' },
  { name: 'Лучшее место', key: 'bestPlace', width: '20%' },
  { name: 'Худшее место', key: 'worstPlace', width: '20%' },
  { name: 'Среднее место', key: 'averagePlace', width: '20%' },
];
