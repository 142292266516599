import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import Cookies from 'universal-cookie';
import './HallOfFame.css';
import { getHallOfFame } from '../../utils/http';


const cookies = new Cookies();
function HallOfFameModule() {
  const [data, setData] = useState([]);

  useEffect(async () => {
    const city = cookies.get('city') || 'msk';
    getHallOfFame({ city })
      .then((res) => setData(orderBy(res.data, 'index')));
  }, []);

  return (
    <>
      <div className="fame">
        <div className="fame__prev">
          <h1>Зал славы SQUIZ</h1>
          <p>Все чемпионы наших барных викторин</p>
        </div>
        <div className="fame-wrapper">
          {data.map((card) => (
            <div className="fame-item">
              <img className="fame-item__img" src={card.logo} alt="logo" />
              <div className="fame-item__name">{card.name}</div>
              <div className="fame-item__title">{card.title}</div>
              <div className="fame-item__tags">{card.seasons.map((season) => <div className="fame-item__tag">{season}</div>)}</div>
            </div>
          ))}
        </div>
        <div className="do-you-want">
          <div className="do-you-want__wrapper">
            <h2>Хочешь сюда?</h2>
            <p>Стань чемпионом со своей командой!</p>
            <a href="https://squiz.ru/schedule" className="btn btn_blue do-you-want__btn">Расписание игр</a>
          </div>
        </div>
      </div>
    </>
  );
}


export default HallOfFameModule;
