/* eslint-disable no-useless-escape */
const getTeamCleanName = (teamName) => teamName.toUpperCase().replace(/\s/g, '').replace(/Ё/g, 'Е').replace(/!/g, '')
  .replace(/\?/g, '')
  .replace(/\./g, '')
  .replace(/,/g, '')
  .replace(/-/g, '')
  .replace(/"/g, '')
  .replace(/\#/g, '')
  .replace(/\…/g, '');

export default getTeamCleanName;
