import React from 'react';
import './Loading.css';


function Loading() {
  return (
    <div className="loader-wrapper">
      <div className="loader" />
      <span className="loader-text">Данные загружаются</span>
    </div>
  );
}

export default Loading;
