const getExperienceValue = (value) => {
  if (value < 100) {
    return 0;
  } if (value >= 100 && value < 300) {
    return 1;
  } if (value >= 300 && value < 600) {
    return 2;
  } if (value >= 600 && value < 1000) {
    return 3;
  } if (value >= 1000 && value < 1500) {
    return 4;
  } if (value >= 1500 && value < 2100) {
    return 5;
  } if (value >= 2100 && value < 2800) {
    return 6;
  } if (value >= 2800 && value < 3600) {
    return 7;
  } if (value >= 3600 && value < 4500) {
    return 8;
  } if (value >= 4500 && value < 5500) {
    return 9;
  } if (value >= 5500 && value < 6600) {
    return 10;
  } if (value >= 6600 && value < 7800) {
    return 11;
  } if (value >= 7800 && value < 9100) {
    return 12;
  } if (value >= 9100 && value < 10500) {
    return 13;
  } if (value >= 10500 && value < 12000) {
    return 14;
  } if (value >= 12000 && value < 13600) {
    return 15;
  } if (value >= 13600 && value < 15300) {
    return 16;
  } if (value >= 15300 && value < 17100) {
    return 17;
  } if (value >= 17100) {
    return 18;
  }
  return 0;
};

export default getExperienceValue;
