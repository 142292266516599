/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import './LoginPage.css';


function LoginPage({
  isLoading, onLogin, isShowPasswordLogin,
}) {
  return (
    <div style={{
      width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '200px',
    }}
    >
      <form action="" className="login-box-wrapper">
        <div>
          <h2 className="login-title">Авторизация</h2>
        </div>
        <input name="email" type="text" placeholder="Логин" id="login-email" disabled={isLoading} className="login-input" />
        <input name="pass" type={isShowPasswordLogin ? 'text' : 'password'} placeholder="Пароль" id="login-password" disabled={isLoading} className="login-input" />
        <a onClick={onLogin} className="login-button">Войти</a>
      </form>
    </div>
  );
}

LoginPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
  isShowPasswordLogin: PropTypes.bool.isRequired,
};

export default LoginPage;
