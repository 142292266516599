/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TableWithFilters from '../../components/TableWithFilters/TableWithFilters';
import { getTeamRatings } from '../../utils/http';
import { routeGenerator } from '../../utils/routes';
import {
  pageSize, defaultSort, shortHeader, detailedHeader, mobileHeader, mobileDetailed,
} from './TeamRatingContants';


const cookies = new Cookies();
function TeamRating({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [ratings, setRatings] = useState([]);
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [paginationCurrent, setPaginationCurrent] = useState(0);
  const [filters, setFilters] = useState(null);
  const [sort, setSort] = useState({});


  const fetchData = (newFilters, newSort, newPagination, isLoadMore) => {
    setIsLoading(true);
    getTeamRatings({
      pageSize,
      page: newPagination,
      sort: `${newSort.field},${newSort.direction}`,
      ...newFilters,
    })
      .then((res) => {
        setPaginationTotal(Math.ceil(res.tableSize / pageSize) - 1);
        setPaginationCurrent(newPagination);
        setRatings(isLoadMore ? [...ratings, ...res.data] : res.data);
        setIsLoading(false);
        window.sessionStorage.setItem('teamRatingFilters', JSON.stringify(newFilters));
        setFilters(newFilters);
        setSort(newSort);
      });
  };

  useEffect(async () => {
    const queryParams = {};
    const urlSearchParamsString = get(history, 'location.search', '').split('?')[1];
    if (urlSearchParamsString) {
      const urlSearchParams = urlSearchParamsString.split('&');
      for (const query of urlSearchParams) {
        queryParams[query.split('=')[0]] = query.split('=')[1];
      }
    }
    if (queryParams.city) {
      cookies.set('city', queryParams.city, { maxAge: 60 * 60 * 24 * 365 });
    }
    const city = get(history, 'location.state.city') || queryParams.city || cookies.get('city') || 'msk';
    const product = get(history, 'location.state.product') || 'squiz';
    const sessionFilters = window.sessionStorage.getItem('teamRatingFilters');

    const historyFilters = {
      city,
      product,
      season: get(history, 'location.state.season') || `${city}-${product}-all`,
    };
    if (sessionFilters) {
      historyFilters.city = JSON.parse(sessionFilters).city;
      historyFilters.product = JSON.parse(sessionFilters).product;
      historyFilters.season = JSON.parse(sessionFilters).season;
    }
    window.sessionStorage.setItem('teamRatingFilters', JSON.stringify(historyFilters));
    setFilters(historyFilters);
    fetchData(historyFilters, defaultSort, 0);
  }, []);


  const onLoadMore = () => {
    if (paginationTotal > paginationCurrent) {
      fetchData(filters, sort, paginationCurrent + 1, true);
    }
  };
  const onFiltersChange = (newFilters) => fetchData(newFilters, sort, 0);
  const onSort = (field, direction) => fetchData(filters, { field, direction }, 0);
  const onSelect = (element) => history.push(routeGenerator.teamRoute(element.id));

  return (
    <>
      {filters && (
      <TableWithFilters
        isShowDetailed
        isShowSearch
        filters={['city', 'product', 'rating']}
        defaultFiltersValue={filters}
        header={shortHeader}
        detailedHeader={detailedHeader}
        data={ratings}
        onSort={onSort}
        onFiltersChange={onFiltersChange}
        onSelect={onSelect}
        onLoadMore={onLoadMore}
        searchPlaceholder="Команда"
        isShowLoadMore={paginationTotal > paginationCurrent}
        isLoading={isLoading}
        mobileHeader={mobileHeader}
        mobileDetailed={mobileDetailed}
        tableClassName="table-small"
        isShowSort
        defaultSort={defaultSort}
        tableFilterClassName="center-position_804"
        tableMobiClassName="table-mobi__exp-center table-mobi__second-link"
      />
      )}
    </>
  );
}

TeamRating.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(TeamRating);

