/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import {
  get, groupBy, minBy, maxBy, orderBy,
} from 'lodash';
import PropTypes from 'prop-types';
import './Team.css';
import TableWithFilters from '../../components/TableWithFilters/TableWithFilters';
import getExperienceValue from '../../utils/getExperienceValue';
import {
  getTeamById, getTeamRatings, getGameResults, getTeamTitle, getPlayerGameResultByTeam,
} from '../../utils/http';
import getExperienceIcon from '../../utils/getExperienceIcon';
import { routeGenerator, routes } from '../../utils/routes';


const headerRatings = [
  {
    name: 'Последняя игра',
    key: 'changeDate',
    width: '148px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Рейтинг',
    key: 'ratingName',
    userClass: 'table-item_left table-item_prop-width',
  },
  {
    name: 'Место',
    key: 'place',
    width: '64px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Игры',
    key: 'gamesPlayed',
    width: '64px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Победы',
    key: 'victories',
    width: '85px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '70px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Ср. балл',
    key: 'average',
    width: '85px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: '1-3 места',
    key: 'prizePlaces',
    width: '93px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: '13 места',
    key: 'thirteenPlaces',
    width: '86px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Лучшее',
    key: 'bestPlace',
    width: '79px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Среднее',
    key: 'averagePlace',
    width: '85px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Худшее',
    key: 'worstPlace',
    width: '77px',
    userClass: 'table-item_center table-item_padding',
  },
];
const mobileHeaderRatings = [
  {
    name: 'Место',
    key: 'place',
    width: '66px',
    userClass: 'table-item_center table-item_padding table-item_ellipsis',
  },
  {
    name: 'Рейтинг',
    key: 'ratingName',
    userClass: 'table-item_left table-item_prop-width',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '72px',
    userClass: 'table-item_right table-item_mobi-score',
  },
];
const mobileHeaderRatingsDetailed = [
  { name: '1-3 места', key: 'prizePlaces', width: '20%' },
  { name: 'Игры', key: 'gamesPlayed', width: '20%' },
  { name: 'Победы', key: 'victories', width: '20%' },
  { name: '13 места', key: 'thirteenPlaces', width: '20%' },
  { name: 'Средний балл', key: 'average', width: '20%' },
  { name: 'Лучшее место', key: 'bestPlace', width: '20%' },
  { name: 'Среднее место', key: 'averagePlace', width: '20%' },
  { name: 'Худшее место', key: 'worstPlace', width: '20%' },
];

const headerGamesDefault = [
  {
    name: 'Дата',
    key: 'date',
    width: '100px',
    userClass: 'table-item_left',
  },
  {
    name: 'Игра',
    key: 'name',
    width: '120px',
    userClass: 'table-item_left',
  },
  {
    name: 'Тип игры',
    key: 'type',
    width: '130px',
    userClass: 'table-item_left',
  },
  {
    name: 'Город',
    key: 'city',
    width: '140px',
    userClass: 'table-item_left',
  },
  {
    name: 'Продукт',
    key: 'product',
    width: '84px',
    userClass: 'table-item_left',
  },
  {
    name: 'Место',
    key: 'place',
    width: '64px',
    userClass: 'table-item_center table-item_padding',
  },
];
const mobileHeaderGames = [
  {
    name: 'Игра',
    key: 'name',
    width: '50%',
    userClass: 'table-item_left',
  },
  {
    name: 'Дата',
    key: 'date',
    width: '50%',
    userClass: 'table-item_right',
  },
];

const headerPlayersDefault = [
  {
    name: 'Опыт',
    key: 'experience',
    width: '68px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Участник',
    key: 'playerName',
    userClass: 'table-item_left table-item_prop-width hyphens-css',
  },
  {
    name: 'Игры',
    key: 'gamesPlayed',
    width: '60px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Баллы',
    key: 'result',
    width: '72px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Победы',
    key: 'victories',
    width: '88px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Ср. балл',
    key: 'average',
    width: '92px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: '1-3 места',
    key: 'prizePlaces',
    width: '93px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: '13 места',
    key: 'thirteenPlaces',
    width: '86px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Лучшее место',
    key: 'bestPlace',
    width: '130px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Среднее',
    key: 'averagePlace',
    width: '92px',
    userClass: 'table-item_center table-item_padding',
  },
  {
    name: 'Худшее',
    key: 'worstPlace',
    width: '92px',
    userClass: 'table-item_center table-item_padding',
  },
];
const mobileHeaderPlayers = [
  {
    name: 'Участник',
    key: 'playerName',
    width: '100%',
    userClass: 'table-item_left',
  },
  {
    name: 'Игры',
    key: 'gamesPlayed',
    width: '72px',
    userClass: 'table-item_center table-item_mobi-score',
  },
];
const mobileHeaderPlayersDetailed = [
  { name: 'Опыт', key: 'experience', width: '100%' },
  { name: 'Победы', key: 'victories', width: '100%' },
  { name: 'Баллы', key: 'result', width: '100%' },
  { name: 'Средний балл', key: 'average', width: '100%' },
  { name: 'Среднее место', key: 'averagePlace', width: '100%' },
  { name: 'Лучшее место', key: 'bestPlace', width: '100%' },
  { name: 'Худшее место', key: 'worstPlace', width: '100%' },
  { name: '1-3 места', key: 'prizePlaces', width: '100%' },
  { name: '13 места', key: 'thirteenPlaces', width: '100%' },
];

function Team({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState({});

  const [tableWidth, setTableWidth] = useState(884);
  const [ratings, setRatings] = useState([]);
  const [ratingsSort, setRatingsSort] = useState({});
  const [ratingsPaginationTotal, setRatingsPaginationTotal] = useState(1);
  const [ratingsPaginationCurrent, setRatingsPaginationCurrent] = useState(0);

  const [games, setGames] = useState([]);
  const [gamesSort, setGamesSort] = useState({});
  const [gamesPaginationTotal, setGamesPaginationTotal] = useState(1);
  const [gamesPaginationCurrent, setGamesPaginationCurrent] = useState(0);
  const [headerGames, setHeaderGames] = useState(headerGamesDefault);
  const [titles, setTitles] = useState([]);

  const [playersSort, setPlayersSort] = useState({ field: 'result', direction: 'desc' });
  const [playersPaginationTotal, setPlayersPaginationTotal] = useState(1);
  const [playersPaginationCurrent, setPlayersPaginationCurrent] = useState(1);
  const [allPlayers, setAllPlayers] = useState([]);
  const [players, setPlayers] = useState([]);

  const fetchRatings = async (newSort, newPagination, isLoadMore) => {
    const id = get(history, 'location.pathname').split('/')[3];
    setIsLoading(true);
    getTeamRatings({
      teamId: id,
      pageSize: 10,
      page: newPagination,
      sort: `${newSort.field},${newSort.direction}`,
    })
      .then((res) => {
        const data = res.data.map((d) => ({ ...d, id: d.ratingId }));
        setRatingsPaginationTotal(Math.ceil(res.tableSize / 10) - 1);
        setRatingsPaginationCurrent(newPagination);
        setRatings(isLoadMore ? [...ratings, ...data] : data);
        setIsLoading(false);
        setRatingsSort(newSort);
      });
  };
  const loadMoreRatings = () => {
    if (ratingsPaginationTotal > ratingsPaginationCurrent) {
      fetchRatings(ratingsSort, ratingsPaginationCurrent + 1, true);
    }
  };
  const sortRatings = (field, direction) => fetchRatings({ field, direction }, 0);

  const fetchGames = async (newSort, newPagination, isLoadMore) => {
    const id = get(history, 'location.pathname').split('/')[3];
    setIsLoading(true);
    getGameResults({
      teamId: id,
      pageSize: 10,
      page: newPagination,
      sort: `${newSort.field},${newSort.direction}`,
    })
      .then((res) => {
        let results = res.data;
        const headerRoundColumns = [];
        const roundsNumber = Math.max(...results.map((gameRes) => gameRes.rounds.length));
        if (res.data.length > 0) {
          for (let i = 1; i <= roundsNumber; i += 1) {
            headerRoundColumns.push({
              name:
  <div className="table-item__desc-hide">
    {i}
  </div>,
              key: `round${i}`,
              width: `${40}px`,
              userClass: 'table-item_center table-item_padding',
            });
            results = results.map((result) => ({
              ...result,
              [`round${i}`]: result.rounds.find((round) => round.number === i)?.result,
            }));
          }
        }
        if (headerRoundColumns.length > headerGames.length - 6) {
          setHeaderGames([...headerGamesDefault, ...headerRoundColumns, {
            name: 'Баллы',
            key: 'result',
            width: '72px',
            userClass: 'table-item_center table-item_padding',
          }]);
        }
        const newTableWidth = 884 + (roundsNumber * 58) + 48;
        setTableWidth(newTableWidth > tableWidth ? newTableWidth : tableWidth);
        setGamesPaginationTotal(Math.ceil(res.tableSize / 10) - 1);
        setGamesPaginationCurrent(newPagination);
        setGames(isLoadMore ? [...games, ...results] : results);
        setIsLoading(false);
        setGamesSort(newSort);
      });
  };
  const loadMoreGames = () => {
    if (gamesPaginationTotal > gamesPaginationCurrent) {
      fetchGames(gamesSort, gamesPaginationCurrent + 1, true);
    }
  };
  const sortGames = (field, direction) => fetchGames({ field, direction }, 0);

  const parsePlayers = (data) => {
    const result = [];
    const groupedByPlayerId = groupBy(data, 'playerId');
    const playerIds = Object.keys(groupedByPlayerId);
    playerIds.forEach((player) => {
      const playerGames = groupedByPlayerId[player];
      const playerObj = {
        id: player,
        experience: getExperienceValue(playerGames[0].playerResult),
        playerName: playerGames[0].playerName,
        gamesPlayed: playerGames.length,
        result: playerGames.reduce((a, e) => a + e.result, 0),
        victories: playerGames.filter((e) => e.place === 1).length,
        bestPlace: minBy(playerGames, 'place').place,
        worstPlace: maxBy(playerGames, 'place').place,
        thirteenPlaces: playerGames.filter((e) => e.place === 13).length,
        prizePlaces: playerGames.filter((e) => e.place === 1 || e.place === 2 || e.place === 3).length,
      };
      playerObj.average = parseFloat((playerObj.result / playerObj.gamesPlayed).toFixed(1));
      playerObj.averagePlace = parseFloat((playerGames.reduce((a, e) => a + e.place, 0) / playerObj.gamesPlayed).toFixed(1));
      result.push(playerObj);
    });
    return orderBy(result, 'result', 'desc');
  };

  useEffect(async () => {
    const id = get(history, 'location.pathname').split('/')[3];
    await getTeamById({ id })
      .then((res) => {
        setTeam({ ...res.data[0] });
      });
    await getTeamTitle({ id })
      .then((res) => {
        setTitles(res.data);
      });
    await fetchRatings({ field: 'changeDate', direction: 'desc' }, 0);
    await fetchGames({ field: 'date', direction: 'desc' }, 0);

    let playerGameResults = [];
    let playerGameResultsResponse = await getPlayerGameResultByTeam({ teamId: id });
    if (playerGameResultsResponse.tableSize > 30) {
      playerGameResultsResponse = await getPlayerGameResultByTeam({ pageSize: playerGameResultsResponse.tableSize, teamId: id });
      playerGameResults = playerGameResultsResponse.data;
    } else {
      playerGameResults = playerGameResultsResponse.data;
    }
    const allPlayersData = parsePlayers(playerGameResults);
    setAllPlayers(allPlayersData);
    setPlayers(allPlayersData.slice(0, 10));
    setPlayersPaginationTotal(Math.ceil(allPlayersData.length / 10));
  }, []);

  const onRedirectGame = (element) => history.push(routeGenerator.gameRoute(element.gameId));
  const onRedirectRating = (element) => history.push({
    pathname: routes.teamRatings,
    state: { city: element.city, product: element.product, season: element.rating_id },
  });

  const sortPlayers = (field, direction) => {
    setAllPlayers(orderBy(allPlayers, field, direction));
    setPlayers(orderBy(players, field, direction));
    setPlayersSort({ field, direction });
  };
  const loadMorePlayers = () => {
    if (playersPaginationTotal > playersPaginationCurrent) {
      setPlayers(allPlayers.slice(0, 10 * (playersPaginationCurrent + 1)));
      setPlayersPaginationCurrent(playersPaginationCurrent + 1);
    }
  };
  const onRedirectPlayer = (element) => history.push(routeGenerator.playerRoute(element.id));

  return (
    <>
      <div className="breadcrumbs">
        <a onClick={() => history.goBack()}>назад</a>
      </div>
      <div className="center-position">
        <div className="team-personal">
          <h1>{team.name}</h1>
          <div className="team-param">
            <div className="team-param__exp team-param__item">
              <span className="team-param__item-title">Опыт</span>
              <div className="team-param__exp-img"><img style={{ height: '30px', width: '56px' }} src={getExperienceIcon(team.experience)} alt="belt" /></div>
            </div>
            <div className="team-param__pow team-param__item">
              <span className="team-param__item-title">Сила</span>
              <span className="team-param__item-body">{`${team.power}`}</span>
            </div>
            <div className="team-param__start team-param__item">
              <span className="team-param__item-title">Первая игра</span>
              <span className="team-param__item-body">{team.firstGameDate}</span>
            </div>
          </div>
        </div>
        <h2 className="main-title">Рейтинги команды</h2>
      </div>
      <div className="table__margin-bottom">
        <TableWithFilters
          isShowSearch={false}
          header={headerRatings}
          data={ratings}
          onSort={sortRatings}
          onFiltersChange={() => {}}
          onSelect={onRedirectRating}
          onLoadMore={loadMoreRatings}
          searchPlaceholder="Название"
          isShowLoadMore={ratingsPaginationTotal > ratingsPaginationCurrent}
          isLoading={isLoading}
          isShowSort
          mobileHeader={mobileHeaderRatings}
          mobileDetailed={mobileHeaderRatingsDetailed}
          tableClassName="table-overflow__1600"
        />
      </div>

      {titles.length > 0 && (
      <div className="grade-wrapper">
        <h2 className="main-title">Титулы</h2>
        {titles.map((t) => (
          <div className="grade-wrapper__item" key={t.id}>
            <span className="grade-wrapper__name">{t.season}</span>
            <span className="grade-wrapper__status">{t.title}</span>
          </div>
        ))}
      </div>
      )}

      <div className="center-position">
        <h2 className="main-title">Игры команды</h2>
      </div>
      <div className="table__margin-bottom">
        <TableWithFilters
          isShowSearch={false}
          header={headerGames}
          data={games}
          onSort={sortGames}
          onFiltersChange={() => {}}
          onSelect={onRedirectGame}
          onLoadMore={loadMoreGames}
          searchPlaceholder="Название"
          isShowLoadMore={gamesPaginationTotal > gamesPaginationCurrent}
          isLoading={isLoading}
          tableClassName="my-table table-normal_2"
          isShowSort
          mobileHeader={mobileHeaderGames}
          mobileDetailed={headerGames.filter((h) => h.key !== 'date')}
          tableMobiClassName="table-mobi__two-col_bigpadding table-mobi__first-link"
          tableWidth={tableWidth}
        />
      </div>

      {players.length > 0 && (
      <div className="center-position table-normal_2">
        <h2 className="main-title">Участники</h2>
      </div>
      )}
      {players.length > 0 && (
      <TableWithFilters
        isShowSearch={false}
        header={headerPlayersDefault}
        data={players}
        onSort={sortPlayers}
        onFiltersChange={() => {}}
        onSelect={(onRedirectPlayer)}
        onLoadMore={loadMorePlayers}
        searchPlaceholder=""
        isShowLoadMore={playersPaginationTotal > playersPaginationCurrent}
        isLoading={isLoading}
        tableClassName="my-table table-normal_2 table-normal_3"
        isShowSort
        mobileHeader={mobileHeaderPlayers}
        mobileDetailed={mobileHeaderPlayersDetailed}
        tableMobiClassName="table-mobi__two-col_bigpadding table-mobi__first-link"
        // tableWidth={tableWidth}
      />
      )}
    </>
  );
}

Team.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Team);

