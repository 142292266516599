/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import './Table.css';
import arrowDown from '../../../../assets/icons/arrowDown.svg';
import getExperienceIcon from '../../../../utils/getExperienceIcon';
import NotFound from '../NotFound/NotFound';
import Loading from '../Loading/Loading';
import { routes } from '../../../../utils/routes';


function Table({
  header, data, onSort, onSelect, isShowSort, tableClassName, defaultSort, isLoading, tableWidth, customPlayerTeamRedirect, history,
}) {
  const [sort, setSort] = useState({ field: null, direction: null });

  useEffect(() => {
    setSort(defaultSort);
  }, []);

  const onHeaderCellClick = (key) => {
    let direction = 'asc';
    if (sort.field) {
      if (sort.field === key && sort.direction === direction) {
        direction = 'desc';
      }
    }
    onSort(key, direction);
    setSort({ field: key, direction });
  };

  const isDisableExpSort = routes.playerRatings === get(history, 'location.pathname', null);

  return (
    <>
      <div className={`table-overflow ${tableClassName} ${data.length === 0 && 'table-overflow__nolength'}`}>
        <div className="table-wrapper" style={tableWidth ? { width: `${tableWidth}px` } : {}}>
          {data.length > 0 && (
          <div className="table-header-wrapper">
            {header.map((headerCell) => {
              const isSort = isShowSort && !(headerCell.key === 'experience' && isDisableExpSort);
              return (
                <div
                  key={headerCell.key}
                  className={`table-header-cell-wrapper ${headerCell.userClass}`}
                  style={headerCell.width ? { width: headerCell.width } : null}
                  onClick={isSort ? () => onHeaderCellClick(headerCell.key) : () => {}}
                >
                  <div className="table-header__title">{headerCell.name}</div>
                  {isSort && <img src={arrowDown} alt="arrowDown" className="table-header-cell-sort-icon" style={headerCell.key === sort.field && sort.direction === 'asc' ? { transform: 'rotate(180deg)' } : null} />}
                </div>
              );
            })}
          </div>
          )}
          {!customPlayerTeamRedirect && data.map((row, i) => (
            <div
              className="table-body-wrapper"
              key={`${row.id}_${i}`}
              onClick={() => onSelect(row)}
            >
              {header.map((headerCell) => (
                <div
                  key={headerCell.key}
                  className={`table-body-cell-wrapper ${headerCell.userClass}`}
                  style={headerCell.width ? { width: headerCell.width } : null}
                >
                  {headerCell.key === 'experience'
                    ? getExperienceIcon(row[headerCell.key])
                      ? <img src={getExperienceIcon(row[headerCell.key])} alt="belt" />
                      : ''
                    : row[headerCell.key]}
                </div>
              ))}
            </div>
          ))}
          {customPlayerTeamRedirect && data.map((row, i) => (
            <div
              className="table-body-wrapper"
              key={`${row.id}_${i}`}
              // onClick={() => onSelect(row)}
            >
              {header.map((headerCell) => (
                <div
                  key={headerCell.key}
                  className={`table-body-cell-wrapper ${headerCell.userClass}`}
                  style={headerCell.width ? { width: headerCell.width } : null}
                  onClick={headerCell.key === 'teamName' ? () => customPlayerTeamRedirect(row) : () => onSelect(row)}
                >
                  {headerCell.key === 'experience'
                    ? getExperienceIcon(row[headerCell.key])
                      ? <img src={getExperienceIcon(row[headerCell.key])} alt="belt" />
                      : ''
                    : row[headerCell.key]}
                </div>
              ))}
            </div>
          ))}
          {data.length === 0 && !isLoading && <NotFound />}
          {isLoading && <Loading />}
        </div>
      </div>
    </>
  );
}

Table.propTypes = {
  onSort: PropTypes.func.isRequired,
  header: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelect: PropTypes.func.isRequired,
  isShowSort: PropTypes.bool.isRequired,
  tableClassName: PropTypes.string.isRequired,
  defaultSort: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  tableWidth: PropTypes.number,
  customPlayerTeamRedirect: PropTypes.func,
  history: PropTypes.shape().isRequired,
};

Table.defaultProps = {
  tableWidth: null,
  customPlayerTeamRedirect: null,
};

export default withRouter(Table);
