import axios from 'axios';
import { get } from 'lodash';
import Cookies from 'universal-cookie';


const cookies = new Cookies();
export default class Structure {
  constructor(structureName, config) {
    this.structureName = structureName;
    this.config = config;
  }

  getData(endpoint, params, options) {
    const token = cookies.get('token');
    return axios.get(`${this.config.apiHost}/good/api/v5/data/${this.structureName}/${endpoint}`, {
      params: {
        appID: this.config.appID,
        sessionID: token,
        ...params,
      },
      ...options,
    })
      .then((response) => get(response, 'data', {}));
  }

  sendData(endpoint, data, headers) {
    const token = cookies.get('token');
    const request = {
      method: 'post',
      url: `${this.config.apiHost}/good/api/v5/data/${this.structureName}/${endpoint}?appID=${this.config.appID}&sessionID=${token}`,
      data,
      headers,
    };
    return axios(request)
      .then((response) => get(response, 'data', {}));
  }

  auth(username, password) {
    return axios.post(`${this.config.apiHost}/good/api/v5/auth?appID=${this.config.appID}`, {
      username, password, appID: this.config.appID, provider: 'rest',
    })
      .then((response) => ({ token: get(response, 'data.result.token', null), isError: false }))
      .catch((error) => {
        if (error.response.status === 400) {
          return ({ data: error.message, isError: true, isPassError: true });
        }
        return ({ data: error.message, isError: true, isPassError: false });
      });
  }
}
