/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import 'swiper/swiper-bundle.css';
import players from '../../../assets/icons/players.svg';
import games from '../../../assets/icons/games.svg';


function Navigation({ onClick, selectedTab }) {
  const tabs = [
    { id: 'games', value: 'Игры', logo: games },
    { id: 'players', value: 'Участники', logo: players },
  ];

  return (
    <>
      <div className="resultsTabs">
        {tabs.map((tab) => (
          <div
            className={`resultsTabs-tab-wrapper ${tab.id === selectedTab ? 'active' : ''}`}
            key={tab.id}
            onClick={() => onClick(tab.id)}
            style={{ display: 'flex' }}
          >
            <img src={tab.logo} alt="logo" className="resultsTabs-tab-icon" />
            <div className="resultsTabs-tab-text">{tab.value}</div>
          </div>
        ))}
      </div>
    </>
  );
}


Navigation.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Navigation;
