import dayjs from 'dayjs';


const getDateSelectText = (dateFrom, dateTo) => {
  let dateSelectText = 'Выберите дату';
  if (dateFrom || dateTo) {
    let dateFromText = '';
    let dateToText = '';
    if (dateFrom) {
      const text = `${dayjs(dateFrom.split('T')[0]).format('MMMM').substring(0, 3)}.`;
      dateFromText = text[0].toUpperCase() + text.slice(1);
      dateFromText = `${dateFromText}/${dayjs(dateFrom.split('T')[0]).format('YYYY')}`;
    }
    if (dateTo) {
      const text = `${dayjs(dateTo.split('T')[0]).format('MMMM').substring(0, 3)}.`;
      dateToText = text[0].toUpperCase() + text.slice(1);
      dateToText = `${dateToText}/${dayjs(dateTo.split('T')[0]).format('YYYY')}`;
    }
    dateSelectText = `${dateFromText}-${dateToText}`;
  }
  return dateSelectText;
};

export default getDateSelectText;
