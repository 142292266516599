/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { orderBy } from 'lodash';
import {
  getProducts, getTypes, getCities, getRatings,
} from '../../../../utils/http';


function AddGameModal({
  onAddGameNextStep, onCloseAddGame, addGameObject, gameImportStatusFetchCounter, city,
}) {
  const [newGameValues, setNewGameValues] = useState({});
  const [products, setProducts] = useState([]);
  const [cities, setCities] = useState([]);
  const [types, setTypes] = useState([]);
  const [ratings, setRatings] = useState([]);

  useEffect(async () => {
    Promise.all([getProducts(), getCities(), getTypes({ pageSize: 60 }), getRatings({ city, pageSize: 120 })])
      .then((data) => {
        setProducts(data[0].data);
        setCities(data[1].data);
        setTypes(data[2].data);
        setRatings(data[3].data);
      });
  }, []);

  return (
    <>
      <div className="shadow active" />
      <div className="modal-common show-block">

        <div>Добавление игры</div>

        {addGameObject.step === 1 && (
        <div>
          <div className="addGameModal-field-wrapper">
            <div className="addGameModal-label">Название</div>
            <input id="form-name" className="addGameModal-input" onChange={(e) => setNewGameValues({ ...newGameValues, name: e?.target?.value })} />
          </div>

          <div className="addGameModal-field-wrapper">
            <div className="addGameModal-label">Дата</div>
            <input type="date" id="form-date" className="addGameModal-input" onChange={(e) => setNewGameValues({ ...newGameValues, date: e?.target?.value })} />
          </div>

          {!city && (
          <div className="addGameModal-field-wrapper">
            <div className="addGameModal-label">Город</div>
            <Select
              isSearchable
              placeholder=""
              isClearable
              onChange={(e) => setNewGameValues({ ...newGameValues, city_id: e?.value })}
              all
              options={orderBy(cities, ['value']).map((u) => ({ label: u.value, value: u.id }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: '1px solid rgba(255, 255, 255, 0.60)',
                  color: 'rgba(255, 255, 255, 0.6)',
                  borderRadius: '10px',
                  backgroundColor: '#333',
                  margin: '20px 0px',
                  height: '46px',
                }),
                option: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: '#FFF',
                }),
                singleValue: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: 'rgba(255, 255, 255, 0.6)',
                }),
              }}
            />
          </div>
          )}

          <div className="addGameModal-field-wrapper">
            <div className="addGameModal-label">Продукт</div>
            <Select
              isSearchable
              placeholder=""
              isClearable
              onChange={(e) => setNewGameValues({ ...newGameValues, product_id: e?.value })}
              all
              options={orderBy(products, ['value']).map((u) => ({ label: u.value, value: u.id }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: '1px solid rgba(255, 255, 255, 0.60)',
                  color: 'rgba(255, 255, 255, 0.6)',
                  borderRadius: '10px',
                  backgroundColor: '#333',
                  margin: '20px 0px',
                  height: '46px',
                }),
                option: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: '#FFF',
                }),
                singleValue: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: 'rgba(255, 255, 255, 0.6)',
                }),
              }}
            />
          </div>

          <div className="addGameModal-field-wrapper">
            <div className="addGameModal-label">Тип</div>
            <Select
              isSearchable
              placeholder=""
              isClearable
              onChange={(e) => setNewGameValues({ ...newGameValues, type_id: e?.value })}
              all
              options={orderBy(types, ['value']).map((u) => ({ label: u.value, value: u.id }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: '1px solid rgba(255, 255, 255, 0.60)',
                  color: 'rgba(255, 255, 255, 0.6)',
                  borderRadius: '10px',
                  backgroundColor: '#333',
                  margin: '20px 0px',
                  height: '46px',
                }),
                option: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: '#FFF',
                }),
                singleValue: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: 'rgba(255, 255, 255, 0.6)',
                }),
              }}
            />
          </div>

          <div className="addGameModal-field-wrapper">
            <div className="addGameModal-label">Рейтинги</div>
            <Select
              isSearchable
              isMulti
              placeholder=""
              onChange={(e) => setNewGameValues({ ...newGameValues, rating_ids: e.map((el) => el.value).join(',') })}
              options={orderBy(ratings, ['value']).map((u) => ({ label: u.value, value: u.id }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: '1px solid rgba(255, 255, 255, 0.60)',
                  color: 'rgba(255, 255, 255, 0.6)',
                  borderRadius: '10px',
                  backgroundColor: '#333',
                  margin: '20px 0px',
                  height: '46px',
                }),
                option: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: '#FFF',
                }),
                singleValue: (styles) => ({
                  ...styles,
                  backgroundColor: '#333',
                  color: 'rgba(255, 255, 255, 0.6)',
                }),
              }}
            />
          </div>

          <div className="addGameModal-field-wrapper">
            <div className="addGameModal-label">Результаты</div>
            <textarea id="form-results" className="addGameModal-input" onChange={(e) => setNewGameValues({ ...newGameValues, result: e?.target?.value })} />
          </div>
        </div>
        )}

        {addGameObject.step === 2 && (
        <div>
          <div style={{ marginBottom: '20px', color: 'red' }}>Вы собираетесь добавить игру со следующими параметрами. Убедитесь, что все корректно и нажмите Далее</div>
          <div style={{ display: 'flex', margin: '5px 0px' }}>
            <div style={{ width: '140px' }}>Название:</div>
            <div>{addGameObject.gameObj.name}</div>
          </div>
          <div style={{ display: 'flex', margin: '5px 0px' }}>
            <div style={{ width: '140px' }}>Дата:</div>
            <div>{addGameObject.gameObj.date}</div>
          </div>
          <div style={{ display: 'flex', margin: '5px 0px' }}>
            <div style={{ width: '140px' }}>Город:</div>
            <div>{addGameObject.gameObj.city_id}</div>
          </div>
          <div style={{ display: 'flex', margin: '5px 0px' }}>
            <div style={{ width: '140px' }}>Продукт:</div>
            <div>{addGameObject.gameObj.product_id}</div>
          </div>
          <div style={{ display: 'flex', margin: '5px 0px' }}>
            <div style={{ width: '140px' }}>Тип:</div>
            <div>{addGameObject.gameObj.type_id}</div>
          </div>
          <div style={{ display: 'flex', margin: '5px 0px' }}>
            <div style={{ width: '140px' }}>Рейтинги:</div>
            <div>{addGameObject.gameObj.rating_ids}</div>
          </div>
          <div style={{ display: 'flex', margin: '5px 0px' }}>
            <div style={{ width: '140px' }}>Результаты:</div>
            <div style={{ whiteSpace: 'break-spaces' }}>{addGameObject.gameObj.result}</div>
          </div>
        </div>
        )}

        {addGameObject.step === 3 && (
        <div>
          {!addGameObject.isError && !addGameObject.isSuccess && <div style={{ marginBottom: '20px', color: 'red' }}>Ожидайте окончания импорта</div>}
          {!addGameObject.isError && !addGameObject.isSuccess && (
          <div>
            Мы опрашиваем Directual о резульатах импорта. Осталось попыток получить статус
            {' '}
            {gameImportStatusFetchCounter}
          </div>
          )}
          {addGameObject.isError && <div style={{ whiteSpace: 'break-spaces' }}>{addGameObject.errorText}</div>}
          {addGameObject.isSuccess && <div>Все успешно загрузилось!</div>}
        </div>
        )}

        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '30px', gap: '20px',
        }}
        >
          {!addGameObject.isSuccess && !addGameObject.isError && <div className="addGameModal-button" onClick={() => onAddGameNextStep(addGameObject.step + 1, newGameValues)}>Далее</div>}
          <div className="addGameModal-button" onClick={onCloseAddGame}>{addGameObject.isSuccess || addGameObject.isError ? 'закрыть' : 'Отменить'}</div>
        </div>

      </div>

    </>
  );
}

AddGameModal.propTypes = {
  onCloseAddGame: PropTypes.func.isRequired,
  onAddGameNextStep: PropTypes.func.isRequired,
  addGameObject: PropTypes.shape().isRequired,
  gameImportStatusFetchCounter: PropTypes.number.isRequired,
  city: PropTypes.bool,
};

AddGameModal.defaultProps = {
  city: null,
};

export default AddGameModal;
