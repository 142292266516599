import belt0 from '../assets/icons/achives/belts/0.svg';
import belt100 from '../assets/icons/achives/belts/100.svg';
import belt300 from '../assets/icons/achives/belts/300.svg';
import belt600 from '../assets/icons/achives/belts/600.svg';
import belt1000 from '../assets/icons/achives/belts/1000.svg';
import belt1500 from '../assets/icons/achives/belts/1500.svg';
import belt2100 from '../assets/icons/achives/belts/2100.svg';
import belt2800 from '../assets/icons/achives/belts/2800.svg';
import belt3600 from '../assets/icons/achives/belts/3600.svg';
import belt4500 from '../assets/icons/achives/belts/4500.svg';
import belt5500 from '../assets/icons/achives/belts/5500.svg';
import belt6600 from '../assets/icons/achives/belts/6600.svg';
import belt7800 from '../assets/icons/achives/belts/7800.svg';
import belt9100 from '../assets/icons/achives/belts/9100.svg';
import belt10500 from '../assets/icons/achives/belts/10500.svg';
import belt12000 from '../assets/icons/achives/belts/12000.svg';
import belt13600 from '../assets/icons/achives/belts/13600.svg';
import belt15300 from '../assets/icons/achives/belts/15300.svg';
import belt17100 from '../assets/icons/achives/belts/17100.svg';


const getExperienceIcon = (value) => {
  if (value < 1) {
    return belt0;
  } if (value >= 1 && value < 2) {
    return belt100;
  } if (value >= 2 && value < 3) {
    return belt300;
  } if (value >= 3 && value < 4) {
    return belt600;
  } if (value >= 4 && value < 5) {
    return belt1000;
  } if (value >= 5 && value < 6) {
    return belt1500;
  } if (value >= 6 && value < 7) {
    return belt2100;
  } if (value >= 7 && value < 8) {
    return belt2800;
  } if (value >= 8 && value < 9) {
    return belt3600;
  } if (value >= 9 && value < 10) {
    return belt4500;
  } if (value >= 10 && value < 11) {
    return belt5500;
  } if (value >= 11 && value < 12) {
    return belt6600;
  } if (value >= 12 && value < 13) {
    return belt7800;
  } if (value >= 13 && value < 14) {
    return belt9100;
  } if (value >= 14 && value < 15) {
    return belt10500;
  } if (value >= 15 && value < 16) {
    return belt12000;
  } if (value >= 16 && value < 17) {
    return belt13600;
  } if (value >= 17 && value < 18) {
    return belt15300;
  } if (value >= 18) {
    return belt17100;
  }
  return belt100;
};

export default getExperienceIcon;
