/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './TableFilters.css';


function FiltersModal({
  onSaveFilterModal, onCloseFilterModal, defaultValue, catalogue,
}) {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(async () => {
    setSelectedValue(defaultValue);
  }, []);

  return (
    <>
      <div className="filter-shadow active" onClick={onCloseFilterModal} />
      <div className="filter-modal-common filter-show-block">
        <div className="filter-modal-common__wrapper">
          {catalogue.map((city) => (
            <div key={city.id} className="filter-modal-city__wrapper">
              <input
                className="filter-modal-city__checkbox"
                type="checkbox"
                id={city.id}
                checked={city.id === selectedValue}
                onChange={(e) => onSaveFilterModal(e.target.checked ? city.id : selectedValue)}
              />
              <label htmlFor={city.id} className="filter-modal-city">{city.value}</label>
            </div>
          ))}
          {/* <div className="filter-modal-button-wrapper">
            <div className="filter-btn filter-btn_clear filter-modal-button" onClick={onCloseFilterModal}>Закрыть</div>
            <div className="filter-btn filter-btn_save filter-modal-button" onClick={onSave}>Применить</div>
          </div> */}
        </div>
      </div>
    </>
  );
}

FiltersModal.propTypes = {
  onSaveFilterModal: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onCloseFilterModal: PropTypes.func.isRequired,
  catalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default FiltersModal;
