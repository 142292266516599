/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableFilters from './components/TableFilters/TableFilters';
import Table from './components/Table/Table';
import './TableWithFilters.css';
import MobileTable from './MobileTable/MobileTable';


function TableWithFilters({
  header, data, onSort, onLoadMore, isShowLoadMore, onSelect, onFiltersChange, searchPlaceholder, isShowSearch, isShowDetailed, isLoading,
  detailedHeader, mobileHeader, mobileDetailed, isShowSort, filters, tableClassName, defaultSort, tableFilterClassName, tableMobiClassName,
  defaultFiltersValue, tableWidth, customPlayerTeamRedirect,
}) {
  const [isShowDetailedOn, setIsShowDetailedOn] = useState(false);
  const [isMobile] = useState(window.innerWidth <= 799);

  return (
    <>
      <div className="TableFilters">
        {isShowSearch && (
        <TableFilters
          onFiltersChange={onFiltersChange}
          searchPlaceholder={searchPlaceholder}
          isShowSearch={isShowSearch}
          isShowDetailed={isShowDetailed && !isMobile}
          onChangeDetailed={setIsShowDetailedOn}
          filters={filters}
          tableFilterClassName={tableFilterClassName}
          defaultFiltersValue={defaultFiltersValue}
        />
        )}
        {isMobile && (
        <MobileTable
          data={data}
          onSelect={onSelect}
          header={mobileHeader}
          headerDetailed={mobileDetailed}
          tableMobiClassName={!isShowDetailedOn && tableMobiClassName ? tableMobiClassName : ''}
          isLoading={isLoading}
        />
        )}
        {!isMobile && (
        <Table
          header={isShowDetailed && isShowDetailedOn ? detailedHeader : header}
          data={data}
          onSort={onSort}
          onSelect={onSelect}
          isShowSort={isShowSort}
          tableClassName={!isShowDetailedOn && tableClassName ? tableClassName : ''}
          defaultSort={defaultSort}
          isLoading={isLoading}
          tableWidth={tableWidth}
          customPlayerTeamRedirect={customPlayerTeamRedirect}
        />
        )}
        {isShowLoadMore && !isLoading && <div className="tableWithFilters-show-more" onClick={onLoadMore}>Показать еще</div>}
      </div>
    </>
  );
}

TableWithFilters.propTypes = {
  onSort: PropTypes.func.isRequired,
  header: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isShowLoadMore: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  isShowSearch: PropTypes.bool,
  isShowDetailed: PropTypes.bool,
  detailedHeader: PropTypes.arrayOf(PropTypes.shape()),
  mobileHeader: PropTypes.arrayOf(PropTypes.shape()),
  mobileDetailed: PropTypes.arrayOf(PropTypes.shape()),
  isShowSort: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.string),
  tableClassName: PropTypes.string,
  tableMobiClassName: PropTypes.string,
  tableFilterClassName: PropTypes.string,
  defaultSort: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  defaultFiltersValue: PropTypes.shape(),
  tableWidth: PropTypes.number,
  customPlayerTeamRedirect: PropTypes.func,
};

TableWithFilters.defaultProps = {
  isShowSearch: true,
  isShowDetailed: false,
  detailedHeader: [],
  mobileHeader: [],
  mobileDetailed: [],
  isShowSort: false,
  filters: [],
  tableClassName: null,
  tableMobiClassName: null,
  defaultSort: { field: null, direction: null },
  tableFilterClassName: 'center-position_1344',
  defaultFiltersValue: null,
  tableWidth: null,
  customPlayerTeamRedirect: null,
};

export default TableWithFilters;
