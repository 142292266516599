import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import TableWithFilters from '../../components/TableWithFilters/TableWithFilters';
import { getGames } from '../../utils/http';
import { routeGenerator } from '../../utils/routes';
import {
  pageSize, defaultSort, header, mobileHeaderDetailed, mobileHeader,
} from './GamesConstants';


const cookies = new Cookies();
function GamesModule({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [paginationCurrent, setPaginationCurrent] = useState(0);
  const [filters, setFilters] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState(null);
  const [sort, setSort] = useState({});


  const fetchData = (newFilters, newSort, newPagination, isLoadMore) => {
    setIsLoading(true);
    getGames({
      pageSize,
      page: newPagination,
      sort: `${newSort.field},${newSort.direction}`,
      ...newFilters,
    })
      .then((res) => {
        setPaginationTotal(Math.ceil(res.tableSize / pageSize) - 1);
        setPaginationCurrent(newPagination);
        setData(isLoadMore ? [...data, ...res.data] : res.data);
        setIsLoading(false);
        window.sessionStorage.setItem('gamesFilters', JSON.stringify(newFilters));
        setFilters(newFilters);
        setSort(newSort);
      });
  };

  useEffect(async () => {
    const newFilters = {
      city: cookies.get('city') || 'msk',
      product: null,
    };
    const sessionFilters = window.sessionStorage.getItem('gamesFilters');
    if (sessionFilters) {
      newFilters.city = JSON.parse(sessionFilters).city;
      newFilters.dateFrom = JSON.parse(sessionFilters).dateFrom;
      newFilters.dateTo = JSON.parse(sessionFilters).dateTo;
    }
    window.sessionStorage.setItem('gamesFilters', JSON.stringify(newFilters));
    setDefaultFilters({ ...newFilters, product: 'all' });
    setFilters(newFilters);
    fetchData(newFilters, defaultSort, 0);
  }, []);


  const onLoadMore = () => {
    if (paginationTotal > paginationCurrent) {
      fetchData(filters, sort, paginationCurrent + 1, true);
    }
  };
  const onFiltersChange = (newFilters) => fetchData(newFilters, sort, 0);
  const onSort = (field, direction) => fetchData(filters, { field, direction }, 0);
  const onSelect = (element) => history.push(routeGenerator.gameRoute(element.id));

  return (
    <>
      <TableWithFilters
        filters={['city', 'date', 'gameType', 'product']}
        defaultFiltersValue={defaultFilters}
        header={header}
        data={data}
        onSort={onSort}
        onFiltersChange={onFiltersChange}
        onSelect={onSelect}
        onLoadMore={onLoadMore}
        searchPlaceholder="Игра"
        isShowLoadMore={paginationTotal > paginationCurrent}
        isLoading={isLoading}
        mobileHeader={mobileHeader}
        mobileDetailed={mobileHeaderDetailed}
        isShowSort
        defaultSort={defaultSort}
        tableClassName="table-middle"
        tableFilterClassName="center-position_1004"
        tableMobiClassName="mobi-table__two-col_leftcentering table-mobi__first-link"
      />
    </>
  );
}

GamesModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(GamesModule);
