/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import TableWithFilters from '../../../components/TableWithFilters/TableWithFilters';
import {
  getGames, addGame, getGameImportStatus,
} from '../../../utils/http';
import AddGameModal from './AddGameModal/AddGameModal';


const cookies = new Cookies();
const pageSize = 30;
const gamesHeader = [{ name: 'Название', key: 'name', width: '25%' }, { name: 'Дата', key: 'date', width: '20%' }, { name: 'Город', key: 'city_id', width: '20%' }, { name: 'Продукт', key: 'product_id', width: '15%' }, { name: 'Тип', key: 'type_id', width: '20%' }];
function Game() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [paginationCurrent, setPaginationCurrent] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState('');
  const [addGameObject, setAddGameObject] = useState({ step: 0, gameObj: null });
  // eslint-disable-next-line no-unused-vars
  const [gameImportStatusFetch, setGameImportStatusFetch] = useState(null);
  const [gameImportStatusFetchCounter, setGameImportStatusFetchCounter] = useState(10);
  const [city] = useState(cookies.get('city'));

  useEffect(async () => {
    getGames({
      pageSize, searchText, page: 0, city,
    })
      .then((res) => {
        setPaginationTotal(Math.ceil(res.tableSize / pageSize));
        setData(res.data || []);
        setIsLoading(false);
      });
    setIsLoading(false);
  }, []);

  const onLoadMore = () => {
    getGames({
      pageSize, searchText, page: paginationCurrent, city,
    })
      .then((res) => {
        setPaginationCurrent(paginationCurrent + 1);
        setData([...data, ...res.data]);
        setIsLoading(false);
      });
  };


  const fetchImportStatus = (id) => {
    let newGameImportStatusFetchCounter = 0;
    setGameImportStatusFetchCounter((prevGameImportStatusFetchCounter) => {
      newGameImportStatusFetchCounter = prevGameImportStatusFetchCounter - 1;
      return newGameImportStatusFetchCounter;
    });
    if (newGameImportStatusFetchCounter !== 0) {
      getGameImportStatus({ id })
        .then((res) => {
          const result = res.data[0];
          if (result.isError || (result.isError !== null && result.isError === false && result.errorMessage === null)) {
            setGameImportStatusFetch((prevGameImportStatusFetch) => {
              clearInterval(prevGameImportStatusFetch);
              return null;
            });
            if (result.isError) {
              setAddGameObject((prevAddGameObj) => ({
                ...prevAddGameObj, isError: true, errorText: result.errorMessage, isSuccess: false,
              }));
            } else {
              setAddGameObject((prevAddGameObj) => ({
                ...prevAddGameObj, isError: false, errorText: null, isSuccess: true,
              }));
            }
          }
        });
    } else {
      setGameImportStatusFetch((prevGameImportStatusFetch) => {
        clearInterval(prevGameImportStatusFetch);
        return null;
      });
      setAddGameObject((prevAddGameObj) => ({
        ...prevAddGameObj, isError: true, errorText: 'Превышено время ожидания обработки. Напишите Карине', isSuccess: false,
      }));
    }
  };

  const onAddGameNextStep = (step, gameObj) => {
    if (step === 2) {
      setAddGameObject({ step: 2, gameObj: { ...gameObj, city_id: city || gameObj.city_id } });
    }
    if (step === 3) {
      addGame(addGameObject.gameObj)
        .then((res) => {
          const newId = res.id;
          setGameImportStatusFetchCounter(10);
          setGameImportStatusFetch(setInterval(() => fetchImportStatus(newId), 5000));
          setAddGameObject({
            step: 3, gameObj: null, isError: false, errorText: '', isSuccess: false,
          });
        });
    }
  };
  const onCloseAddGame = () => setAddGameObject({
    step: 0, gameObj: null, isError: false, errorText: '', isSuccess: false,
  });

  return (
    <>
      <div style={{
        width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px',
      }}
      >
        <div className="adminModule-addGame-button" onClick={() => setAddGameObject({ ...addGameObject, step: 1 })}>Добавить игру</div>
      </div>
      <TableWithFilters
        header={gamesHeader}
        data={data}
        onSort={() => {}}
        onSelect={() => {}}
        onLoadMore={onLoadMore}
        isShowLoadMore={paginationTotal > paginationCurrent}
        isLoading={isLoading}
      />
      {addGameObject.step !== 0 && (
      <AddGameModal
        onAddGameNextStep={onAddGameNextStep}
        onCloseAddGame={onCloseAddGame}
        addGameObject={addGameObject}
        gameImportStatusFetchCounter={gameImportStatusFetchCounter}
        city={city}
      />
      )}
    </>
  );
}


export default Game;
