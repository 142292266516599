/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import getExperienceIcon from '../../../utils/getExperienceIcon';
import arrowDown from '../../../assets/icons/arrowDown.svg';
import './MobileTable.css';
import NotFound from '../components/NotFound/NotFound';
import Loading from '../components/Loading/Loading';


function MobileTable({
  data, header, onSort, headerDetailed, isShowSort, onSelect, defaultSort, tableMobiClassName, isLoading,
}) {
  const [sort, setSort] = useState({ field: null, direction: null });
  const [expandedObjectId, setExpandedObjectId] = useState(null);
  useEffect(() => {
    setSort(defaultSort);
  }, []);
  const onHeaderCellClick = (key) => {
    let direction = 'asc';
    if (sort.field) {
      if (sort.field === key && sort.direction === direction) {
        direction = 'desc';
      }
    }
    onSort(key, direction);
    setSort({ field: key, direction });
  };
  const onEpand = (objectId) => {
    if ((!expandedObjectId || expandedObjectId !== objectId) && headerDetailed.length > 0) {
      setExpandedObjectId(objectId);
    } else {
      setExpandedObjectId(null);
    }
  };
  return (
    <>
      <div className={`${tableMobiClassName} table-mobi`}>
        {data.length > 0 && (
        <div className="table-mobi__header">
          {header.map((headerCell) => (
            <div
              className={`table-mobi__header_rel ${headerCell.userClass}`}
              key={headerCell.key}
              style={headerCell.width ? { width: headerCell.width } : null}
              onClick={isShowSort ? () => onHeaderCellClick(headerCell.key) : () => {}}
            >
              {headerCell.name}
              {isShowSort && <img src={arrowDown} alt="arrowDown" className="table-header-cell-sort-icon" style={headerCell.key === sort.field && sort.direction === 'asc' ? { transform: 'rotate(180deg)' } : null} />}
            </div>
          ))}
        </div>
        )}

        <div className="table-mobi__content">
          {data.length === 0 && !isLoading && <NotFound />}
          {isLoading && <Loading />}
          {data.map((row) => (
            <div className={`table-mobi__item ${row.id === expandedObjectId ? 'active' : ''}`} key={row.id}>
              <div className="table-mobi__title" onClick={() => onEpand(row.id)}>
                {header.map((headerCell) => (
                  <span
                    key={headerCell.key}
                    style={headerCell.width ? { width: headerCell.width } : null}
                    className={`table-mobi__row-count ${headerCell.userClass}`}
                    onClick={headerCell.key === 'name' || headerCell.key === 'teamName' || headerCell.key === 'playerName' ? () => onSelect(row) : () => {}}
                  >
                    {headerCell.key === 'experience' && getExperienceIcon(row[headerCell.key]) ? <img style={{ height: '20px' }} src={getExperienceIcon(row[headerCell.key])} alt="belt" /> : row[headerCell.key]}
                  </span>
                ))}
              </div>
              <div className="table-mobi__hide">
                {headerDetailed.map((headerCell) => (
                  <div className="table-mobi__string" key={headerCell.key}>
                    <span className="table-mobi__string_title">{headerCell.name}</span>
                    <span className="table-mobi__string_param">
                      {headerCell.key === 'experience'
                        ? getExperienceIcon(row[headerCell.key]) ? <img style={{ height: '20px' }} src={getExperienceIcon(row[headerCell.key])} alt="belt" /> : ''
                        : row[headerCell.key]}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

MobileTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  isShowSort: PropTypes.bool.isRequired,
  header: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  headerDetailed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableMobiClassName: PropTypes.string.isRequired,
  defaultSort: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MobileTable;

