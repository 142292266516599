export const routes = {
  any: '/',
  teams: '/results/teams',
  players: '/results/players',
  games: '/results/games',
  teamRatings: '/results/teamRatings',
  playerRatings: '/results/playerRatings',
  achievements: '/results/achievements',
  hallOfFame: '/results/hallOfFame',
  login: '/login',
  admin: '/admin',
  game: '/results/game/:id',
  team: '/results/team/:id',
  player: '/results/player/:id',
  playerRating: '/results/playerRating/:id',
  teamRating: '/results/teamRating/:id',
};


export const routeGenerator = {
  gameRoute: (id) => `${routes.game.replace(':id', id)}`,
  teamRatingRoute: (id) => `${routes.teamRating.replace(':id', id)}`,
  teamRoute: (id) => `${routes.team.replace(':id', id)}`,
  playerRoute: (id) => `${routes.player.replace(':id', id)}`,
  playerRatingRoute: (id) => `${routes.playerRating.replace(':id', id)}`,
};

