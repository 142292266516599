import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import './PlayerRating.css';
import TableWithFilters from '../../components/TableWithFilters/TableWithFilters';
import { getPlayerRatings } from '../../utils/http';
import { routeGenerator } from '../../utils/routes';
import {
  pageSize, header, detailedHeader, mobileHeader, mobileDetailed, defaultSort,
} from './PlayerRatingConstants';
import citiesCatalogue from '../../utils/citiesCatalogue';


const cookies = new Cookies();
function PlayerRating({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [ratings, setRatings] = useState([]);
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [paginationCurrent, setPaginationCurrent] = useState(0);
  const [filters, setFilters] = useState(null);
  const [sort, setSort] = useState({});


  const fetchData = (newFilters, newSort, newPagination, isLoadMore) => {
    const searchFilters = {
      city: citiesCatalogue.find((c) => newFilters.city === c.id)?.value,
      isMonthly: newFilters.type === 'month',
      searchText: newFilters.searchText,
    };
    setIsLoading(true);
    getPlayerRatings({
      pageSize,
      page: newPagination,
      sort: `${newSort.field},${newSort.direction}`,
      ...searchFilters,
    })
      .then((res) => {
        setPaginationTotal(Math.ceil(res.tableSize / pageSize) - 1);
        setPaginationCurrent(newPagination);
        setRatings(isLoadMore ? [...ratings, ...res.data] : res.data);
        setIsLoading(false);
        window.sessionStorage.setItem('playerRatingFilters', JSON.stringify(newFilters));
        setFilters(newFilters);
        setSort(newSort);
      });
  };

  useEffect(async () => {
    const newFilters = {
      city: cookies.get('city') || 'msk',
      type: 'month',
    };
    const sessionFilters = window.sessionStorage.getItem('playerRatingFilters');
    if (sessionFilters) {
      newFilters.city = JSON.parse(sessionFilters).city;
      newFilters.type = JSON.parse(sessionFilters).type;
    }
    window.sessionStorage.setItem('playerRatingFilters', JSON.stringify(newFilters));
    setFilters(newFilters);
    fetchData(newFilters, defaultSort, 0);
  }, []);


  const onLoadMore = () => {
    if (paginationTotal > paginationCurrent) {
      fetchData(filters, sort, paginationCurrent + 1, true);
    }
  };
  const onFiltersChange = (newFilters) => fetchData(newFilters, sort, 0);
  const onSort = (field, direction) => fetchData(filters, { field, direction }, 0);
  const onSelect = (element) => history.push(routeGenerator.playerRoute(element.id));

  const onRedirectTeam = (element) => history.push(routeGenerator.teamRoute(element.teamId));

  return (
    <>
      <TableWithFilters
        isShowDetailed
        isShowSearch
        header={header}
        data={ratings}
        detailedHeader={detailedHeader}
        onSort={onSort}
        onFiltersChange={onFiltersChange}
        onSelect={onSelect}
        onLoadMore={onLoadMore}
        searchPlaceholder="Участник"
        isShowLoadMore={paginationTotal > paginationCurrent}
        isLoading={isLoading}
        filters={['city', 'type']}
        defaultFiltersValue={filters}
        defaultSort={defaultSort}
        isShowSort
        tableClassName="table-middle"
        tableFilterClassName="center-position_1004"
        mobileHeader={mobileHeader}
        tableMobiClassName="table-mobi__second-link"
        mobileDetailed={mobileDetailed}
        customPlayerTeamRedirect={onRedirectTeam}
      />
    </>
  );
}

PlayerRating.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(PlayerRating);

