/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { orderBy } from 'lodash';
import citiesCatalogue from '../../../../utils/citiesCatalogue';


function AddLoteryModal({
  step, onNextStep, onCloseModal, newObj, customError, setNewObj, gameResults, onChangeTeam, isShowCitySelect, customCity,
}) {
  useEffect(async () => {
    if (!isShowCitySelect) {
      setNewObj({ ...newObj, city: customCity });
    }
  }, []);
  return (
    <>
      <div className="shadow active" onClick={onCloseModal} />
      <div className="modal-common show-block" style={{ width: '80vw' }}>

        <div className="modal-common__header" style={{ fontSize: '25px', marginBottom: '40px' }}>Добавление лотереи</div>
        {customError && <div style={{ margin: '10px 0px', color: 'red' }}>{`ОШИБКА ${customError}`}</div>}
        {step === 1 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <input id="name" className="tableFilters-filters-input" placeholder="Название игры" style={{ padding: '0px 10px', borderRadius: '10px' }} />
          {isShowCitySelect && (
          <Select
            id="city"
            isSearchable
            placeholder="Город"
            isClearable
            onChange={(e) => setNewObj({ ...newObj, city: e })}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: '1px solid rgba(255, 255, 255, 0.60)',
                color: 'rgba(255, 255, 255, 0.6)',
                borderRadius: '10px',
                backgroundColor: '#333',
                marginTop: '20px',
                height: '46px',
              }),
              option: (styles) => ({
                ...styles,
                backgroundColor: '#333',
                color: '#FFF',
              }),
              singleValue: (styles) => ({
                ...styles,
                backgroundColor: '#333',
                color: 'rgba(255, 255, 255, 0.6)',
              }),
            }}
            all
            options={orderBy(citiesCatalogue, ['value']).map((u) => ({ label: u.value, value: u.id }))}
          />
          )}
          <input id="date" className="tableFilters-filters-input" placeholder="Дата (в формате 2023-12-28)" style={{ padding: '0px 10px', borderRadius: '10px', marginTop: '20px' }} />
          <label style={{ margin: '20px 0px', width: 'fit-content' }}>
            НЕ учитывать в рейтингах:
            {' '}
            <input type="checkbox" name="myCheckbox" id="isOut" />
          </label>
          <textarea id="result" className="tableFilters-filters-input" placeholder="Результаты в формате email;имя;команда" style={{ padding: '0px 10px', borderRadius: '10px' }} />
          <div style={{ fontSize: '11px' }}>Результаты в формате email;имя;команда</div>
        </div>
        )}

        {step === 2 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: '10px 0px', color: 'red' }}>Вы собираетесь добавить результаты лотереии со следующими параметрами. Убедитесь, что все корректно, поправьте результаты (если необходимо) и нажмите Далее</div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '100px' }}>Город:</div>
            <div>{newObj.city}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '100px' }}>Название:</div>
            <div>{newObj.name}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '100px' }}>Дата:</div>
            <div>{newObj.date}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '100px' }}>Тип:</div>
            <div>{newObj.type}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '100px' }}>Продукт:</div>
            <div>{newObj.product}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '100px' }}>Результаты:</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {newObj?.playerResults?.map((player) => (
                <div
                  style={{
                    display: 'flex', gap: '10px', alignItems: 'center', margin: '5px 0px',
                  }}
                  key={JSON.stringify(player)}
                >
                  <div style={{ width: '100px' }}>{player.name}</div>
                  <div style={{ width: '150px' }}>{player.email}</div>
                  <div style={{ width: '150px' }}>{player.teamName}</div>
                  <Select
                    isSearchable
                    placeholder="команда"
                    isClearable
                    value={{ label: player.team, value: player.team }}
                    onChange={(e) => onChangeTeam(player.email, player.name, e?.value)}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: '1px solid rgba(255, 255, 255, 0.60)',
                        color: 'rgba(255, 255, 255, 0.6)',
                        borderRadius: '10px',
                        backgroundColor: '#333',
                        height: '46px',
                        width: '300px',
                      }),
                      option: (styles) => ({
                        ...styles,
                        backgroundColor: '#333',
                        color: '#FFF',
                      }),
                      singleValue: (styles) => ({
                        ...styles,
                        backgroundColor: '#333',
                        color: 'rgba(255, 255, 255, 0.6)',
                      }),
                    }}
                    all
                    options={orderBy(gameResults, ['teamName'])?.map((u) => ({ label: u.teamName, value: u.teamName }))}
                  />
                  <div style={{ width: '50px' }}>{player.place}</div>
                  <div style={{ width: '50px' }}>{player.result}</div>
                </div>
              ))}

            </div>
          </div>

        </div>
        )}

        {step === 3 && (
        <div>
          {newObj.counter && !newObj.isError && !newObj.isSuccess && <div style={{ marginBottom: '20px', color: 'red' }}>Ожидайте окончания импорта</div>}
          {newObj.counter && !newObj.isError && !newObj.isSuccess && (
          <div>
            Мы опрашиваем Directual о резульатах импорта. Осталось попыток получить статус
            {' '}
            {newObj.counter}
          </div>
          )}
          {newObj.isError && <div style={{ whiteSpace: 'break-spaces' }}>{newObj.errorText}</div>}
          {newObj.isSuccess && <div>Все успешно загрузилось!</div>}
        </div>
        )}

        <div
          style={{
            padding: '10px 20px',
            borderRadius: '15px',
            backgroundColor: '#02ACE5',
            marginBottom: '20px',
            cursor: 'pointer',
            color: '#fff',
            width: 'fit-content',
            marginTop: '20px',
          }}
          onClick={() => onNextStep(step + 1)}
        >
          {step === 1 || step === 2 ? 'Далее'
            : step === 3 ? 'Добавить' : 'Закрыть'}
        </div>
      </div>
    </>
  );
}

AddLoteryModal.propTypes = {
  step: PropTypes.number.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  newObj: PropTypes.shape().isRequired,
  customError: PropTypes.string,
  setNewObj: PropTypes.func.isRequired,
  gameResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeTeam: PropTypes.func.isRequired,
  isShowCitySelect: PropTypes.bool.isRequired,
  customCity: PropTypes.string,
};

AddLoteryModal.defaultProps = {
  customError: null,
  customCity: null,
};

export default AddLoteryModal;


