import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import md5 from 'md5';
import { withRouter } from 'react-router';
import { routes } from '../../utils/routes';
import { auth } from '../../utils/http';
import LoginPage from './LoginPage';


const cookies = new Cookies();
function LoginModule({ history }) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cookies.get('token')) {
      history.push(routes.profile);
    }
  }, []);

  const onLogin = () => {
    const login = document.getElementById('login-email')?.value || null;
    const password = document.getElementById('login-password')?.value || null;

    setIsLoading(true);
    auth({ login, password: md5(password) })
      .then((rsp) => {
        if (rsp.code === 'success') {
          cookies.set('token', rsp.token, { maxAge: 60 * 60 * 24 });
          cookies.set('city', rsp.city, { maxAge: 60 * 60 * 24 });
          history.push({
            pathname: routes.admin,
            state: { isLogin: true },
          });
        }
        if (rsp.code === 'error') {
          setIsLoading(false);
        }
      });
  };


  return (
    <>
      <LoginPage
        onLogin={onLogin}
        isLoading={isLoading}
      />
    </>
  );
}

LoginModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(LoginModule);
